/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Column, usePagination, useTable } from 'react-table';
import { SelectUser } from '../filters/TaskApproversFilter';
import { SortIcon } from '../../../assets/images';
import CONSTANTS from '../../../constants';
import {
    IInactiveApproverAdminDocuments,
    IInactiveDocuments,
    IInactiveApproverActions,
    IPaginationData,
    IUserOptionValue,
    IInactiveAProverLoading,
} from '../../../interfaces';
import { NoDocumentsFound } from '../../General';
import Pagination from '../../pagination/Pagination';
import { SkeletonText } from '../../Skeleton';
import { TaskApproverActionButtons } from '../actions/taskApproversActions';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { inactiveString } from '../../../utils';

const { TASK_APPROVERS } = STATIC_CONTENT;

const DocNumberDocument = (row: Row<IInactiveApproverAdminDocuments>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        original: { docNumber, documentId, active },
    } = row;

    const onClick = () => {
        document.body.classList.remove('admin-side-navigation');
        navigate({
            pathname: `/app${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
            search: `?doc_id=${documentId}`,
        });
    };
    let documentDetailsURL = '';
    if (location.search.includes('ADMIN_TASKS')) {
        documentDetailsURL = `/app${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${documentId}&prev_page_type=ADMIN_TASKS`;
    } else {
        documentDetailsURL = `/app${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${documentId}`;
    }
    if (active)
        return (
            <a
                href={documentDetailsURL}
                className="link-style"
                onClick={() => onClick()}
                onKeyPress={() => onClick()}
            >
                <SkeletonText text={docNumber} width={200} />
            </a>
        );
    return <SkeletonText text={docNumber} width={200} />;
};
const ActiveStatus = (row: Row<IInactiveApproverAdminDocuments>) => {
    const {
        original: { active },
    } = row;
    return <div className={`status-pill ${active ? 'active' : 'inactive'}`} >
        {`${active ? 'Active' : 'Inactive'}`}
    </div >



};

const COLUMNS: Column<IInactiveApproverAdminDocuments>[] = [
    {
        Header: ({ handleSelectAll, selectAll }) => (
            <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll(!selectAll)}
            />
        ),
        accessor: 'documentId',
        Cell: ({
            value,
            handleSingleCheckBox,
            SelectedDocuments,
            row: {
                original: { inActiveApproverId, documentId },
            },
        }) => (
            <input
                type="checkbox"
                id={value.toString()}
                disabled={!documentId}
                checked={
                    SelectedDocuments.map((d: IInactiveDocuments) =>
                        inactiveString(d.documentId, d.oldApproverId),
                    ).indexOf(inactiveString(value, inActiveApproverId)) !== -1
                }
                onChange={() => handleSingleCheckBox(value, inActiveApproverId)}
            />
        ),
    },
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberDocument {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Document Type',
        accessor: 'documentType',
        Cell: ({ value }) => <SkeletonText text={value} width={140} />,
        minWidth: 140,
    },
    {
        Header: 'Owner',
        accessor: 'owner',
        Cell: ({ value }) => <SkeletonText text={value} width={150} />,
        minWidth: 200,
    },
    {
        Header: 'Approver Name',
        accessor: 'inActiveApprover',
        Cell: ({ value }) => <SkeletonText text={value} width={150} />,
        minWidth: 200,
    },
    {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => <ActiveStatus {...row} />,
        minWidth: 75,

    },
    {
        Header: 'New Approver Name',
        accessor: 'selectedApprover',
        Cell: ({
            value,
            updateInactiveApprover,
            SelectedDocuments,
            selectAll,
            row: {
                original: { documentId, ownerId, inActiveApproverId, active },
            },
        }) => {
            if (active)
                return <SelectUser
                    owner={value || null}
                    hideUsers={[ownerId]}
                    updateOwner={(user) => updateInactiveApprover(user, documentId, inActiveApproverId)}
                    isDisabled={SelectedDocuments.length > 0 || selectAll || !documentId}
                    documentId={documentId}
                />
            return <></>;
        },
        minWidth: 200,
    },
    {
        Header: 'Actions',
        Cell: ({
            selectedRowIds = [],
            selectAll,
            handleRowAction,
            row: {
                original: { documentId, active },
            },
            loadingState,
        }) => {
            if (active)
                return (<TaskApproverActionButtons
                    onButtonClickAction={(type) => handleRowAction(type, documentId)}
                    removeDisabled={
                        selectedRowIds?.length > 0 || selectAll || !documentId || !!loadingState
                    }
                    replaceDisabled={
                        selectedRowIds?.length > 0 || selectAll || !documentId || !!loadingState
                    }
                    className="small-buttons"
                    removeLoading={loadingState?.type === 'REMOVE' && loadingState.id === documentId}
                    replaceLoading={loadingState?.type === 'REASSIGN' && loadingState.id === documentId}
                />);
            return <></>;
        },
        minWidth: 110,
    },
];

interface Props {
    data: IInactiveApproverAdminDocuments[];
    selectAll: boolean;
    SelectedDocuments: IInactiveDocuments[];
    pagination: IPaginationData;
    loadingState: IInactiveAProverLoading | null;
    handleSelectAll: (selectAll: boolean) => void;
    handleSingleCheckBox: (documentId: number, oldApproverId: number) => void;
    handleRowAction: (type: IInactiveApproverActions, documentId: number) => void;
    updateInactiveApprover: (
        user: IUserOptionValue,
        documentId: number,
        inactiveApproverId: number,
    ) => void;
    fetchDocuments: (pageIndex: number, pageSize: number) => void;
}

const InactiveApproversAdminTable: React.FC<Props> = ({
    data,
    selectAll,
    SelectedDocuments,
    pagination,
    loadingState,
    handleRowAction,
    handleSelectAll,
    handleSingleCheckBox,
    updateInactiveApprover,
    fetchDocuments,
}) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable<IInactiveApproverAdminDocuments>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                // sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
            selectAll,
            SelectedDocuments,
            loadingState,
            handleRowAction,
            handleSelectAll,
            handleSingleCheckBox,
            updateInactiveApprover,
        },
        usePagination,
    );

    useEffect(() => {
        fetchDocuments(pageIndex, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize]);

    return (
        <div className="inactive-approvers-admin-table">
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth
                                                    ? column.minWidth
                                                    : 'auto',
                                            },
                                        })}
                                    >
                                        {column.render('Header')}
                                        {column.isSorted && (
                                            <SortIcon
                                                className={`sort ${column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                            />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            className={cell.column.id}
                                            {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
                {page.length === 0 && <NoDocumentsFound heading={TASK_APPROVERS.NO_DOCUMENTS} />}
            </div>
        </div>
    );
};

export default InactiveApproversAdminTable;
