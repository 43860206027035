/* eslint-disable max-lines-per-function */
import React, { createContext, useState, useEffect, useContext, useReducer, ReactNode } from 'react';

import { toast } from 'react-toastify';
import { getActiveTask, getDocumentVersion, getTaskDetails, updateDocument, updateDocumentVersion, updateDocumentVersionDeadline, updateDocumentVersionFieldsData } from '../API/document';
import { getUserGroups } from '../API/groups';
import { IApprovalDocStage, IDocumentInfo, IField, IFieldsData } from '../interfaces/document';
import { IExternalApprover, IOptionValue, IReviewers, IUserType } from '../interfaces';
import { useQueryParams } from './query-params-provider';
import { useAuthDataContext } from './user-auth-provider';
import { IDocVersionObject, IDocumentTypeInfo } from '../interfaces/document-type';
import { IAttachmentConfig, IFileTypeDoc } from '../interfaces/attachment';
import CONSTANTS from '../constants';
import { getDynamicValues } from '../API/fields';
import {
    documentReviewersReducer,
    IDocumentReviewersAction,
} from '../reducers/document-reviewers-reducer';
import {
    documentApproversReducer,
    externalApproversReducer,
    IDocumentApproversAction,
    IDocumentExternalApproversAction,
} from '../reducers/document-approver-reducer';
import { IDocumentAction, taskdocumentReducer } from '../reducers/task-document-reducer';
import { IVersionOwner } from '../interfaces/user';

const initialNewDocInfo: IDocumentInfo = {
    author: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    itemCreator: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    submitter: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    versionOwner: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    approvedVersionId: 0,
    render: false,
    assignee: {
        employeeName: CONSTANTS.LOADING.TEXT,
        id: 0,
        email: CONSTANTS.LOADING.TEXT,
    },
    confidential: false,
    docState: 'NEW',
    docNumber: CONSTANTS.LOADING.TEXT,
    title: CONSTANTS.LOADING.TEXT,
    description: CONSTANTS.LOADING.TEXT,
    isFavorite: false,
    approvedOn: 0,
    effectiveFrom: new Date(),
    effectiveTill: new Date(),
    effectivePeriod: 0,
    isEverGreen: false,
    autoRenew: false,
    sunsetPeriod: 0,
    activeDocumentState: 'NEW',
    documentLock: false,
};
const initialFields: IField[] = [];
const initialFieldsData: IFieldsData[] = [];
const intialTripDetails = { from: '', to: '', fromDate: '', toDate: '' };
const defaultInitialVersionValue: IDocVersionObject = {
    majorVersionNumber: 1,
    minorVersionNumber: 0
};
const initialDocTypeInfo: IDocumentTypeInfo = {
    title: CONSTANTS.LOADING.TEXT,
    description: CONSTANTS.LOADING.TEXT,
    version: CONSTANTS.LOADING.TEXT,
    status: 'DRAFT',
    isConfidential: false,
    allowExport: false,
    isDCO: false,
    fullWidthTitleDesc: false,
    singlePageDocCreation: false,
    restrictCommentForPublished: false,
    showCommentsAfterSubmitDate: false,
    allowDCO: false,
    allowReviseByAnyone: false,
    allowViewingInProcessDocByAnyone: false,
    allowCustomVersioning: false,
    defaultInitialVersion: defaultInitialVersionValue,
    blockRevise: false,
    anyoneCanEditDraft: false,
    allowObsolete: false,
    allowRerelease: false,
    enableApprovalDeadline: false,
    defaultApprovalDeadline: 5,
    hideEventTimeline: false,
    storeAndShowActivity: false,
    isContract: false,
    allowTemplateGeneration: false,
    detailsSectionTitle: undefined,
    attachmentSectionTitle: undefined,
    approversSectionTitle: undefined,
    approverTimeStamp: undefined,
    isFileType: false,
    allowFileTypeAttachment: false
};

const initialVersionOwnerData: IVersionOwner = {
    id: 0,
    email: '',
    firstName: '',
    lastname: '',
    employeeName: '',
    jobTitle: '',
    managerId: 0,
    activeUser: false,
}

interface IDocumentContext {
    documentId: number;
    activeDocVersion: IOptionValue;
    docInfo: IDocumentInfo;
    fields: IField[];
    fieldsData: { [key: string]: unknown };
    reviewers: IReviewers[];
    approvers: IApprovalDocStage[];
    externalApprovers: IExternalApprover[];
    userType: IUserType;
    isTaskActive: boolean;
    canViewTaskDetails: boolean;
    hideUsers: number[];
    collectComment: boolean;
    collectCommentRejection: boolean;
    docTypeInfo: IDocumentTypeInfo;
    delegatorInfo: IOptionValue;
    taskId: string;
    attachmentConfig: IAttachmentConfig[];
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    setActiveDocVersion: React.Dispatch<React.SetStateAction<IOptionValue>>;
    fetchDocumentVersion: () => Promise<void>;
    setHideUsers: React.Dispatch<React.SetStateAction<number[]>>;
    updateFields: () => Promise<void>;
    isDocFetched: boolean;
    reviewersDispatch: React.Dispatch<IDocumentReviewersAction>;
    externalApproversDispatch: React.Dispatch<IDocumentExternalApproversAction>;
    approversDispatch: React.Dispatch<IDocumentApproversAction>;
    approverStatus: string;
    isGroupMember: boolean;
    taskFieldsData: IFieldsData[];
    taskDocumentDispatch: React.Dispatch<IDocumentAction>;
    isTravelAuthorizationForm: boolean;
    accessibleByCurrentUser: boolean;
    fetchTaskDetails: () => Promise<void>;
    isMemo: boolean;
    publishOnceApproved: boolean;
    isSunsetEnabled: boolean;
    activeVersionOwnerInfo: IVersionOwner;
    activeApprovalStatusId: number;
    isToAndCcEditable: boolean;
    isLeadershipForm: boolean;
    isEndDateAsked: boolean;
    isItACustomaerMeeting: boolean;
    travelRequestFor: IOptionValue;
    isDCOwithApprovers: boolean;
    activeVersionApprovalDeadline: number;
    setActiveVersionApprovalDeadline: React.Dispatch<React.SetStateAction<number>>;
    checklist: { question: string; value: null | string }[] | null;
    setchecklist: React.Dispatch<React.SetStateAction<{ question: string; value: null | string }[] | null>>;
    saveDeadlineData: () => Promise<void>;
}

export const taskDetailContext = createContext<IDocumentContext>({
    documentId: 0,
    activeDocVersion: { label: '', value: 0 },
    travelRequestFor: { label: 'Self', value: 'Self' },
    isTravelAuthorizationForm: false,
    docInfo: initialNewDocInfo,
    fields: initialFields,
    fieldsData: {},
    reviewers: [],
    approvers: [],
    externalApprovers: [],
    userType: 'OTHER',
    isTaskActive: false,
    canViewTaskDetails: true,
    hideUsers: [],
    collectComment: false,
    collectCommentRejection: false,
    docTypeInfo: initialDocTypeInfo,
    delegatorInfo: { label: '', value: 0 },
    taskId: '',
    attachmentConfig: [],
    setDocInfo: () => { },
    setActiveDocVersion: () => { },
    fetchDocumentVersion: () => Promise.resolve(),
    setHideUsers: () => { },
    isDocFetched: false,
    updateFields: () => Promise.resolve(),
    taskFieldsData: initialFieldsData,
    taskDocumentDispatch: () => null,
    reviewersDispatch: () => null,
    approversDispatch: () => null,
    externalApproversDispatch: () => null,
    approverStatus: '',
    isGroupMember: true,
    accessibleByCurrentUser: true,
    fetchTaskDetails: () => Promise.resolve(),
    isMemo: true,
    publishOnceApproved: false,
    isSunsetEnabled: false,
    activeVersionOwnerInfo: initialVersionOwnerData,
    activeApprovalStatusId: 0,
    isToAndCcEditable: false,
    isLeadershipForm: false,
    isEndDateAsked: false,
    isItACustomaerMeeting: false,
    isDCOwithApprovers: false,
    activeVersionApprovalDeadline: 0,
    setActiveVersionApprovalDeadline: () => { },
    checklist: null,
    setchecklist: () => { },
    saveDeadlineData: () => Promise.resolve(),
});
type TaskDetailProviderProps = {
    children: ReactNode; // Accepts ReactNode as children
};

const TaskDetailProvider: React.FC<TaskDetailProviderProps> = ({ children }) => {
    const { getQueryParam } = useQueryParams();
    const [isItACustomaerMeeting, setIsItACustomaerMeeting] = useState<boolean>(false);
    const [taskId, setTaskId] = useState('');
    const [documentId, setDocumentId] = useState(Number(getQueryParam('doc_id')));
    const [activeDocVersion, setActiveDocVersion] = useState<IOptionValue>({ label: '', value: 0 });
    const { user } = useAuthDataContext();
    const [travelRequestFor, setTravelRequestFor] = useState<IOptionValue>({ label: 'Self', value: 'Self' });
    const [isGroupMember, setIsGroupMember] = useState(true);
    const [docInfo, setDocInfo] = useState(initialNewDocInfo);
    const [docTypeInfo, setDocTypeInfo] = useState(initialDocTypeInfo);
    const [taskFieldsData, taskDocumentDispatch] = useReducer(taskdocumentReducer, []);
    const [fields, setFields] = useState<IField[]>([]);
    const [fieldsData, setFieldsData] = useState<{ [key: string]: unknown }>({});
    const [userType, setUserType] = useState<IUserType>('OTHER');
    const [isTaskActive, setIsTaskActive] = useState(false);
    const [canViewTaskDetails, setCanViewTaskDetails] = useState(true);
    const [hideUsers, setHideUsers] = useState<number[]>([]);
    const [collectComment, setCOllectComment] = useState(false);
    const [collectCommentRejection, setCollectCommentRejection] = useState(false);
    const [delegatorInfo, setDelegatorInfo] = useState<IOptionValue>({ label: '', value: 0 });
    const [attachmentConfig, setAttachmentConfig] = useState<IAttachmentConfig[]>([]);
    const [isDocFetched, setIsDocFetched] = useState(false);
    const [reviewers, reviewersDispatch] = useReducer(documentReviewersReducer, []);
    const [externalApprovers, externalApproversDispatch] = useReducer(externalApproversReducer, []);
    const [approvers, approversDispatch] = useReducer(documentApproversReducer, []);
    const [approverStatus, setapproverStatus] = useState('');
    const [accessibleByCurrentUser, setAccessibleByCurrentUser] = useState(true);
    const [isMemo, setIsMemo] = useState<boolean>(true);
    const [publishOnceApproved, setPublishOnceApproved] = useState(true);
    const [isSunsetEnabled, setIsSunsetEnabled] = useState(true);
    const [activeVersionOwnerInfo, setActiveVersionOwnerInfo] = useState<IVersionOwner>(initialVersionOwnerData);
    const [activeApprovalStatusId, setActiveApprovalStatusId] = useState(0);
    const [isToAndCcEditable, setIsToAndCcEditable] = useState(false);
    const [isLeadershipForm, setisLeadershipForm] = useState(false);
    const [isEndDateAsked, setIsEndDateAsked] = useState(false);
    const [isDCOwithApprovers, setIsDCOwithApprovers] = useState(false);
    const [activeVersionApprovalDeadline, setActiveVersionApprovalDeadline] = useState<number>(0);
    const [checklist, setchecklist] = useState<{ question: string; value: null | string }[] | null>(null);
    const [isTravelAuthorizationForm, setIsTravelAuthorizationForm] = useState<boolean>(false);
    let isTravelForm = false;

    const fetchDocumentVersion = async () => {
        if (documentId && activeDocVersion.value) {
            const response = await getDocumentVersion(
                documentId,
                activeDocVersion.value as number,
                user.id,
                true
            );
            if (response) {
                setFields(response.fields);
                setFieldsData(response.data);
                setHideUsers(response.hideUsers);
                setFieldsData(response.data);
                setCOllectComment(response.collectComment);
                setCollectCommentRejection(response.collectCommentRejection);
                setAttachmentConfig(response.attachmentConfig);
                setPublishOnceApproved(response.docTypeConfig.publishOnceApproved);
                setIsSunsetEnabled(response.docTypeConfig.isSunsetEnabled);
                setIsEndDateAsked(response.docTypeConfig.isEndDateAsked);
                setActiveVersionOwnerInfo(response.versionOwner);
                setActiveVersionApprovalDeadline(response?.approvalDeadline ? response?.approvalDeadline : 0);
                setDocTypeInfo(response.docTypeInfo);
                setchecklist(response.checklist);

                setActiveApprovalStatusId(response.activeApprovalStatusId);
                const { prefixes } = response;
                if (prefixes.length > 0) {
                    const temp = prefixes.some(
                        (item) => item.type === 'static' && item.value === 'MEMO',
                    );
                    setIsMemo(temp);
                }
                const checkTravelForm = prefixes.some(
                    (item) => item.type === 'static' && item.value === 'TRAP',
                );
                setIsTravelAuthorizationForm(checkTravelForm);
                isTravelForm = checkTravelForm;
            }
        }
    };

    const updateFields = async () => {
        // console.log(`inside task provider, taskFieldsData: ${JSON.stringify(taskFieldsData)}`)
        const response = await updateDocumentVersionFieldsData(
            taskFieldsData,
            documentId,
            activeDocVersion.value as number,
        );
        if (response?.apiStatus === 'FAILURE') {
            toast.error(response.errorMessage);
        }
    }

    const saveDeadlineData = async (showToast = true) => {
        const response = await updateDocumentVersionDeadline(documentId, activeDocVersion.value as number, activeApprovalStatusId, approvers, activeVersionApprovalDeadline, activeVersionOwnerInfo.id);
        if (response?.apiStatus === 'FAILURE') {
            toast.error('Deadline update failed.');
        } else if (response?.apiStatus === 'SUCCESS' && showToast) {
            toast.success('Successfully updated the deadline');
        }
    }

    const DCOwithApprovers = async (approversList: IApprovalDocStage[], prefixCode: any) => {
        if (prefixCode.includes('DCO')) {
            let approverscount = 0;
            // eslint-disable-next-line array-callback-return
            approversList.map((stage: any) => {
                if (stage?.approvers.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    stage?.approvers.map((stageApprovers: any) => {
                        if (stageApprovers?.employee) approverscount += 1;
                        if (stageApprovers?.adHoc.length > 0)
                            approverscount += stageApprovers?.adHoc.length;
                    });
                }
            });
            if (approverscount > 1) {
                setIsDCOwithApprovers(true);
            }
        }
    }

    const fetchTaskDetails = async () => {
        if (taskId) {
            const res = await getTaskDetails(taskId, user.id);
            if (res) {
                setDocumentId(res.documentId);
                setDocInfo(res.docInfo);
                setDocTypeInfo(res.docTypeInfo);
                setUserType(res.userType);
                setIsTaskActive(res.isActive);
                setCanViewTaskDetails(res.activeAssigner); // res.activeAssigner
                setDelegatorInfo(res.delegatorInfo);
                reviewersDispatch({ type: 'update', value: res.reviewers });
                approversDispatch({ type: 'update', value: res.approvers });
                externalApproversDispatch({ type: 'update', value: res.externalApprovers });
                setActiveDocVersion(res.documentVersion);
                setIsDocFetched(true);
                setapproverStatus(res.approverStatus);
                setAccessibleByCurrentUser(res.accessibleByCurrentUser);
                if (res.userType === "APPROVER" && res.docInfo.docState === "UNDER_REVIEW" && !res.docInfo.render) {
                    setIsToAndCcEditable(true);
                }
                else if (res.docInfo.render && res.docInfo.assignee && Object.keys(res.docInfo.assignee).length !== 0 && user.id === res.docInfo.assignee.id) {
                    setIsToAndCcEditable(true);
                } else {
                    setIsToAndCcEditable(false);
                }
                if (res.prefixCode === 'LPNF') {
                    setisLeadershipForm(true);
                }
                await DCOwithApprovers(res.approvers, res.docTypeInfo?.prefixCode);
            } else {
                toast.error(CONSTANTS.ERROR_TASK_NOT_AVAILABLE);
            }
        }
    };

    const fetchApprovers = async () => {
        if (taskId) {
            const res = await getTaskDetails(taskId, user.id);
            if (res) {
                approversDispatch({ type: 'update', value: res.approvers });
                await DCOwithApprovers(res.approvers, res.docTypeInfo?.prefixCode);
            }
        }
    };

    const findGroupMember = async () => {
        if (taskId) {
            const list: number[] = [];
            const approverList: any = [];
            if (approvers.length > 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const approver of approvers) {
                    approverList.push(...approver.approvers);
                }
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const approverData of approverList) {
                if (Object.prototype.hasOwnProperty.call(approverData, 'groupId')) {
                    list.push(approverData.groupId);
                }
            }
            if (list.length > 0) {
                const res = await getUserGroups();
                if (res?.apiStatus === 'SUCCESS') {
                    const data = res.myGroups;
                    const GroupList = data.map((d) => d.id);
                    const isFounded = list.some((ai) => GroupList.includes(ai));
                    setIsGroupMember(isFounded);
                }
            }
            else{
                setIsGroupMember(false);
            }
        }
    };

    useEffect(() => {
        const update = async () => {
            const updatedFieldsData: IFieldsData[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const field of fields) {
                let value: unknown = CONSTANTS.DEFAULT_VALUE[field.dataType.label];
                if (fieldsData[field.name]) {
                    if (field.selectType === 'dynamic') {
                        if (field.uiControl.label === 'Single Select Dropdown') {
                            if ((fieldsData[field.name] as string[]).length > 0) {
                                // eslint-disable-next-line no-await-in-loop
                                const dynamicOptions = await getDynamicValues(
                                    field.id,
                                    '',
                                    fieldsData[field.name] as string[],
                                );
                                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                                    value = dynamicOptions.dynamicData;
                                } else {
                                    toast.error(CONSTANTS.ERROR_406);
                                }
                            }
                        }
                        if (field.uiControl.label === 'Multi Select Dropdown') {
                            if ((fieldsData[field.name] as string[]).length > 0) {
                                // eslint-disable-next-line no-await-in-loop
                                const dynamicOptions = await getDynamicValues(
                                    field.id,
                                    '',
                                    fieldsData[field.name] as string[],
                                );
                                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                                    value = dynamicOptions.dynamicData;
                                } else {
                                    toast.error(CONSTANTS.ERROR_406);
                                }
                            }
                        }
                    } else if (field.selectType === 'static') {
                        if (field.uiControl.label === 'Single Select Dropdown') {
                            value = (fieldsData[field.name] as string[]).map((o) => ({
                                label: o,
                                value: o,
                            }));
                        }
                        if (field.uiControl.label === 'Multi Select Dropdown') {
                            value = (fieldsData[field.name] as string[]).map((o) => ({
                                label: o,
                                value: o,
                            }));
                        }
                    } else if (field.uiControl.label === 'Travel Form') {
                        if (Array.isArray(fieldsData[field.name])) {
                            value = {
                                travelType: "Round Trip",
                                travelDetails: fieldsData[field.name],
                            }
                        } else {
                            value = fieldsData[field.name];
                        }
                    } else if (fieldsData[field.name]) {
                        value = fieldsData[field.name];
                    }
                }
                else if (
                    field.dataType.label === 'date-time' &&
                    field.uiControl.label === 'OnlyDate'
                ) {
                    value = '';
                } else if (field.uiControl.label === 'Travel Form') {
                    if (Array.isArray(value)) {
                        value = {
                            travelType: "Round Trip",
                            travelDetails: value.length > 0 ? value : [{ ...intialTripDetails }],
                        }
                    }
                }
                updatedFieldsData.push({
                    name: field.name,
                    value,
                });
            }
            const customerMeetingField = updatedFieldsData.filter((item) => item.name === 'customerMetting');
            if (customerMeetingField.length > 0) {
                setIsItACustomaerMeeting(customerMeetingField[0].value);
            }
            const travelRequestForField = updatedFieldsData.filter((item) => item.name === 'travelRequestFor');
            if (travelRequestForField.length > 0) {
                setTravelRequestFor(travelRequestForField[0].value[0]);
            }
            taskDocumentDispatch({ type: 'push', value: updatedFieldsData });
            if (fieldsData?.file) {
                taskDocumentDispatch({ type: 'file-list', value: fieldsData?.file as IFileTypeDoc[], inputName: 'file' })
            }
        };
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldsData]);

    useEffect(() => {
        fetchApprovers();
        findGroupMember();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideUsers]);

    useEffect(() => {
        fetchDocumentVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocVersion]);

    /* useEffect(() => {
        const fetchDocument = async () => {
            if (documentId) {
                const response = await getDocument(documentId);
                if (response?.apiStatus === 'SUCCESS') {
                    setActiveDocVersion(response.documentInfo.activeDocumentVersion);
                    setIsDocFetched(true)
                }
            }
        };
        fetchDocument();
    }, [documentId]); */

    useEffect(() => {
        fetchTaskDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId]);

    useEffect(() => {
        // check if task is active
        const getTaskId = async () => {
            const task = await getActiveTask(getQueryParam('task_id'));
            if (task) {
                setTaskId(task.taskid);
            }
        }
        if (getQueryParam('task_id') !== "") { getTaskId(); }
    }, [getQueryParam]);


    return (
        <taskDetailContext.Provider
            value={{
                documentId,
                docInfo,
                fields,
                fieldsData,
                reviewers,
                approvers,
                externalApprovers,
                userType,
                activeDocVersion,
                isTaskActive,
                canViewTaskDetails,
                hideUsers,
                collectComment,
                collectCommentRejection,
                docTypeInfo,
                delegatorInfo,
                taskId,
                attachmentConfig,
                setDocInfo,
                setActiveDocVersion,
                fetchDocumentVersion,
                reviewersDispatch,
                approversDispatch,
                externalApproversDispatch,
                setHideUsers,
                taskFieldsData,
                taskDocumentDispatch,
                updateFields,
                isDocFetched,
                approverStatus,
                isGroupMember,
                accessibleByCurrentUser,
                fetchTaskDetails,
                isMemo,
                publishOnceApproved,
                isSunsetEnabled,
                activeVersionOwnerInfo,
                activeApprovalStatusId,
                isToAndCcEditable,
                isLeadershipForm,
                isEndDateAsked,
                isItACustomaerMeeting,
                isTravelAuthorizationForm,
                travelRequestFor,
                isDCOwithApprovers,
                activeVersionApprovalDeadline,
                setActiveVersionApprovalDeadline,
                checklist,
                setchecklist,
                saveDeadlineData
            }}
        >{children}</taskDetailContext.Provider>
    );
};

export const useTaskDetailContext = (): IDocumentContext => useContext(taskDetailContext);

export default TaskDetailProvider;
