/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
import React from 'react';
import './Search.scss';
import Filters from '../../components/filters/Filters';
import { NoDocumentsFound } from '../../components/General';
import Pagination from '../../components/pagination/Pagination';
import SortOption from '../../components/sort-option/SortOption';
import SearchCard from '../../components/search-card/SearchCard';
import CONSTANTS from '../../constants';
import { useSearch } from '../../contexts/search-provider';
import SearchedParams from '../../components/searched-prams/SearchedParams';
import { ISearch } from '../../interfaces/dashboard';
import STATIC_CONTENT from '../../constants/StaticContent';
import ConfidentialSearchCard from '../../components/search-card/ConfidentialSearchCard';

const loadingSearch = () => {
    const search: ISearch[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 9; i++) {
        search.push({
            docId: CONSTANTS.LOADING.NUMBER,
            authorId: CONSTANTS.LOADING.NUMBER,
            authorName: CONSTANTS.LOADING.TEXT,
            createdDate: CONSTANTS.LOADING.DATE,
            docNumber: CONSTANTS.LOADING.TEXT,
            files: CONSTANTS.LOADING.NUMBER,
            title: CONSTANTS.LOADING.TEXT,
            highlighted: [
                {
                    text: CONSTANTS.LOADING.TEXT,
                    foundIn: CONSTANTS.LOADING.TEXT,
                },
            ],
            isFavorite: false,
            status: 'NEW',
            version: CONSTANTS.LOADING.TEXT,
            description: CONSTANTS.LOADING.TEXT,
            isConfidential: false,
            documentTypeName: 'MEMO'
        });
    }

    return search;
};

const Search: React.FC = () => {
    const {
        filtersConfig,
        favoriteDocIds,
        isLoading,
        searchDocuments,
        searchPagination,
        firstLoad,
        filterSearch,
        fetchFavoriteDocIds,
        setSearchPagination,
        onReset,
        sortOption,
        setSortOption,
        sortOrder,
        setSortOrder,
        filters,
        deletedFilter,
    } = useSearch();

    const loadingData = loadingSearch();

    const noOfPages = Math.ceil(searchPagination.totalCount / searchPagination.pageSize);

    const { docTypesColors } = CONSTANTS;

    return (
        <div className="search">
            <div className="section">
                <div className="filters-section">
                    <Filters
                        filtersConfig={filtersConfig}
                        documentCount={searchPagination.totalCount}
                        filterSearch={filterSearch}
                        resetFilters={() => onReset()}
                        loading={isLoading}
                        usedBy="SEARCH"
                        searchfilters={filters}
                        deletedFilter={deletedFilter}
                    />
                </div>
                <div className="doc-list">
                    {firstLoad ? (
                        <div className="no-documents-found">
                            <h1>Start typing in Search bar...</h1>
                        </div>
                    ) : isLoading && searchDocuments.length > 0 ? (
                        <div className="search-wrapper">
                            {loadingData.map((searchDoc) => (
                                <>
                                    {searchDoc.isConfidential ? (
                                        <ConfidentialSearchCard
                                            key={searchDoc.docId}
                                            searchDocument={searchDoc}
                                            favoriteDocIds={favoriteDocIds}
                                            fetchFavoriteDocIds={fetchFavoriteDocIds}
                                            docTypeColor={
                                                searchDoc.documentTypeName === 'MEMO'
                                                    ? docTypesColors.MEMO
                                                    : docTypesColors.SPEC
                                            }
                                        />
                                    ) : (
                                        <SearchCard
                                            key={searchDoc.docId}
                                            searchDocument={searchDoc}
                                            favoriteDocIds={favoriteDocIds}
                                            fetchFavoriteDocIds={fetchFavoriteDocIds}
                                            docTypeColor={
                                                searchDoc.documentTypeName === 'MEMO'
                                                    ? docTypesColors.MEMO
                                                    : docTypesColors.SPEC
                                            }
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                    ) : !isLoading && searchDocuments.length === 0 ? (
                        <>
                            <SearchedParams />
                            <NoDocumentsFound heading={STATIC_CONTENT.SEARCH.NO_DOCUMENT} />
                        </>
                    ) : (
                        <>
                            {searchDocuments.length > 0 && (
                                <>
                                    <div className="search-pagination-merge">
                                        <SearchedParams />
                                        <SortOption
                                            sortType={sortOption}
                                            setSortType={setSortOption}
                                            sortOrder={sortOrder}
                                            setSortOrder={setSortOrder}
                                        />
                                        {searchPagination.totalCount >
                                            CONSTANTS.NO_OF_TABLE_ROWS && (
                                            <>
                                                <Pagination
                                                    nextPage={() =>
                                                        setSearchPagination({
                                                            ...searchPagination,
                                                            pageIndex:
                                                                searchPagination.pageIndex + 1,
                                                        })
                                                    }
                                                    canNextPage={
                                                        searchPagination.pageIndex < noOfPages - 1
                                                    }
                                                    canPreviousPage={
                                                        searchPagination.pageIndex >= 1
                                                    }
                                                    pageIndex={searchPagination.pageIndex}
                                                    pageOptions={Array.from(
                                                        Array(noOfPages).keys(),
                                                    )}
                                                    pageSize={searchPagination.pageSize}
                                                    previousPage={() =>
                                                        setSearchPagination({
                                                            ...searchPagination,
                                                            pageIndex:
                                                                searchPagination.pageIndex - 1,
                                                        })
                                                    }
                                                    setPageSize={(pageSize) =>
                                                        setSearchPagination({
                                                            ...searchPagination,
                                                            pageSize,
                                                            pageIndex: 0,
                                                        })
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div className="search-wrapper">
                                        {searchDocuments.map((searchDoc) => (
                                            <>
                                                {searchDoc.isConfidential ? (
                                                    <ConfidentialSearchCard
                                                        key={searchDoc.docId}
                                                        searchDocument={searchDoc}
                                                        favoriteDocIds={favoriteDocIds}
                                                        fetchFavoriteDocIds={fetchFavoriteDocIds}
                                                        docTypeColor={
                                                            searchDoc.documentTypeName === 'MEMO'
                                                                ? docTypesColors.MEMO
                                                                : docTypesColors.SPEC
                                                        }
                                                    />
                                                ) : (
                                                    <SearchCard
                                                        key={searchDoc.docId}
                                                        searchDocument={searchDoc}
                                                        favoriteDocIds={favoriteDocIds}
                                                        fetchFavoriteDocIds={fetchFavoriteDocIds}
                                                        docTypeColor={
                                                            searchDoc.documentTypeName === 'MEMO'
                                                                ? docTypesColors.MEMO
                                                                : docTypesColors.SPEC
                                                        }
                                                    />
                                                )}
                                            </>
                                        ))}
                                    </div>
                                    {searchPagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                                        <>
                                            <Pagination
                                                nextPage={() =>
                                                    setSearchPagination({
                                                        ...searchPagination,
                                                        pageIndex: searchPagination.pageIndex + 1,
                                                    })
                                                }
                                                canNextPage={
                                                    searchPagination.pageIndex < noOfPages - 1
                                                }
                                                canPreviousPage={searchPagination.pageIndex >= 1}
                                                pageIndex={searchPagination.pageIndex}
                                                pageOptions={Array.from(Array(noOfPages).keys())}
                                                pageSize={searchPagination.pageSize}
                                                previousPage={() =>
                                                    setSearchPagination({
                                                        ...searchPagination,
                                                        pageIndex: searchPagination.pageIndex - 1,
                                                    })
                                                }
                                                setPageSize={(pageSize) =>
                                                    setSearchPagination({
                                                        ...searchPagination,
                                                        pageSize,
                                                        pageIndex: 0,
                                                    })
                                                }
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Search;
