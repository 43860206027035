/* eslint-disable no-debugger */
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../button/Button';
import { PlusIconSvg } from '../../../assets/images';
import './AddToExistingFilesModal.scss';
import Pagination from '../../pagination/Pagination';
import CONSTANTS from '../../../constants';
import { IPaginationData } from '../../../interfaces';
import { getAllFileTypeDocs } from '../../../API/dashboard';
import { SkeletonText } from '../../Skeleton';
import { IFileTypeDoc } from '../../../interfaces/attachment';
import { useDocumentContext } from '../../../contexts/document-provider';
import { getDocument } from '../../../API/document';
import { formatDateTime } from '../../../utils/date';

interface Props {
    show: boolean;
    handleClose: () => void;
    childDocVersionId: number;
    latestrelationArr: any[];
}
// eslint-disable-next-line max-lines-per-function
const AddToExistingFilesModal: React.FC<Props> = ({
    show,
    handleClose,
    childDocVersionId,
    latestrelationArr,
}) => {
    const [isSelectLoading, setisSelectLoading] = useState(false);
    const [selectedDocId, setSelectedDocId] = useState<number | null>(null);
    const [dcoData, setDcoData] = useState(null);
    const [selectedDocVersionId, setSelectedVersionDocId] = useState<number | null>(null);
    const [selectedDocNumber, setSelectedDocNumber] = useState<string | null>(null);
    const { documentDispatch, documentId, fieldsData } = useDocumentContext();

    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS_IN_MODAL,
        totalCount: 0,
    };


    const defaultFileTypeDoc: IFileTypeDoc = {
        id: CONSTANTS.LOADING.NUMBER,
        parentDocId: CONSTANTS.LOADING.NUMBER,
        docVersionId: CONSTANTS.LOADING.NUMBER,
        attachmentId: CONSTANTS.LOADING.NUMBER,
        name: CONSTANTS.LOADING.TEXT,
        extension: CONSTANTS.LOADING.TEXT,
        mimeType: CONSTANTS.LOADING.TEXT,
        size: CONSTANTS.LOADING.NUMBER,
        attachmentType: CONSTANTS.LOADING.TEXT,
        displayName: CONSTANTS.LOADING.TEXT,
        fileNumber: CONSTANTS.LOADING.TEXT,
        title: CONSTANTS.LOADING.TEXT,
        description: CONSTANTS.LOADING.TEXT,
        isPrimary: false,
        uploadedOn: CONSTANTS.LOADING.NUMBER,
        releasedOn: CONSTANTS.LOADING.NUMBER
    }

    const defaultdrafDCOs = () => {
        const drafDCOs: IFileTypeDoc[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 4; i++) {
            drafDCOs.push(defaultFileTypeDoc);
        }
        return drafDCOs;
    };

    const [drafDCOs, setdrafDCOs] = useState<IFileTypeDoc[]>(defaultdrafDCOs());
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [selectedFileTypeDoc, setSelectedFileTypeDoc] = useState<IFileTypeDoc>()

    const handleCloseModal = () => {
        setdrafDCOs(defaultdrafDCOs());
        setPagination(DEFAULT_PAGINATION_DATA);
        setSelectedDocId(null);
        setDcoData(null);
        setSelectedVersionDocId(null);
        setSelectedDocNumber(null);
        handleClose();
    };

    const SelectAddToDCO = async () => {
        setisSelectLoading(true);
        documentDispatch({
            type: 'file-list',
            value: selectedFileTypeDoc ? [selectedFileTypeDoc] : [defaultFileTypeDoc],
            inputName: 'file'
        })
        setisSelectLoading(false);
        handleCloseModal();
    };

    /* eslint-disable max-lines-per-function */
    const FileNumberColumn = (row: Row<IFileTypeDoc>) => {
        const {
            original: { id, parentDocId, docVersionId, attachmentId, attachmentType, displayName, extension, mimeType, name, size, fileNumber, title, description, isPrimary, uploadedOn, releasedOn },
        } = row;

        return (
            <div className="align-radio-in-modal">
                <input
                    type="radio"
                    id={`radio-${id}`}
                    name="submit"
                    value="submit"
                    onChange={() => {
                        if (id) {
                            setSelectedDocId(id);
                        }
                        // setSelectedVersionDocId(docVersionId);
                        setSelectedDocNumber(fileNumber);
                        // setDcoData(data);
                        const fileTypeDocData: IFileTypeDoc = {
                            id,
                            parentDocId,
                            docVersionId,
                            attachmentId,
                            attachmentType,
                            displayName,
                            extension,
                            mimeType,
                            name,
                            size,
                            fileNumber,
                            title,
                            description,
                            isPrimary,
                            uploadedOn,
                            releasedOn
                        }
                        setSelectedFileTypeDoc(fileTypeDocData);
                    }}
                    checked={id === selectedDocId}
                />
                <Link
                    className="link-style DCO-modal"
                    style={{ textDecoration: 'none' }}
                    to={{
                        pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                        search: `?doc_id=${id}`,
                    }}
                    target="_blank"
                >
                    <SkeletonText text={fileNumber} width={200} />
                </Link>
            </div>
        );
    };
    /* eslint-enable max-lines-per-function */
    const FileTitleColumn = (row: Row<IFileTypeDoc>) => {
        const {
            original: { title },
        } = row;

        return (
            <>
                <SkeletonText text={title} width={200} />
            </>
        );
    };
    const FileAttachmentNameColumn = (row: Row<IFileTypeDoc>) => {
        const {
            original: { name },
        } = row;

        return (
            <>
                <SkeletonText text={name} width={200} />
            </>
        );
    };
    const FileUploadedOnColumn = (row: Row<IFileTypeDoc>) => {
        const {
            original: { uploadedOn },
        } = row;
        let date; let dateText;
        if (uploadedOn === CONSTANTS.LOADING.NUMBER) {
            date = CONSTANTS.LOADING.TEXT;
            dateText = CONSTANTS.LOADING.TEXT;
        }
        else {

            date = new Date(uploadedOn * 1000)
            dateText = formatDateTime(date);
        }
        return (
            <>
                <SkeletonText text={dateText} width={200} />
            </>
        );
    };
    const FileReleasedOnColumn = (row: Row<IFileTypeDoc>) => {
        const {
            original: { releasedOn },
        } = row;
        let date;
        if (releasedOn === CONSTANTS.LOADING.NUMBER) {
            date = CONSTANTS.LOADING.TEXT;
        }
        else if (releasedOn && releasedOn !== 'N/A') {
            date = new Date(releasedOn * 1000);
            date = date.toLocaleString('en-GB');
        } else {

            date = 'N/A'
        }

        return (
            <>
                <SkeletonText text={date} width={200} />
            </>
        );
    };


    const DRAFT_DCOS_COLUMNS: Column<IFileTypeDoc>[] = [
        {
            Header: 'File Number',
            accessor: 'fileNumber',
            Cell: ({ row }) => <FileNumberColumn {...row} />,
            minWidth: 120,
        },
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ row }) => <FileTitleColumn {...row} />,
            minWidth: 150,
        },
        {
            Header: 'Attachment',
            accessor: 'name',
            Cell: ({ row }) => <FileAttachmentNameColumn {...row} />,
            minWidth: 100,
        },
        {
            Header: 'Updated On',
            accessor: 'uploadedOn',
            Cell: ({ row }) => <FileUploadedOnColumn {...row} />,
            minWidth: 100,
        },
        {
            Header: 'Published On',
            accessor: 'releasedOn',
            Cell: ({ row }) => <FileReleasedOnColumn {...row} />,
            minWidth: 100,
        },
    ];

    const columns = useMemo(() => DRAFT_DCOS_COLUMNS, [selectedDocId]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        gotoPage,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data: drafDCOs,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            },
            updateResults: () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                fetchDocuments();
            },
        },
        useSortBy,
        usePagination,
    );

    /* eslint-disable max-lines-per-function */
    const fetchDocuments = async () => {
        const queryParams = {
            // limit: pageSize.toString(),
            // offset: (pageIndex * pageSize).toString(),
        };

        const response = await getAllFileTypeDocs(queryParams);
        if (response?.apiStatus === 'SUCCESS') {
            const getDocRes = await getDocument(documentId, true)
            let filesInPreviousVersions: any[] = [];
            if (getDocRes?.apiStatus === 'SUCCESS' && getDocRes.documentVersions && getDocRes.documentVersions.length) {
                const allVersions = getDocRes.documentVersions;
                /* eslint-disable no-plusplus */
                for (let i = 0; i < allVersions.length; i++) {
                    const currVersion = allVersions[i];
                    if (currVersion?.state !== 'DRAFT') {
                        const currVersionFiles = currVersion?.data?.file;
                        filesInPreviousVersions = [...filesInPreviousVersions, ...currVersionFiles]
                    }
                }
            }
            const filesInCurrentVersion = fieldsData.filter((a) => a.name === 'file'); // [0].value
            const finalFileList: IFileTypeDoc[] = [];

            if (response?.fileTypeDocs) {
                const allFileTypeDocs = response.fileTypeDocs;
                /* eslint-disable no-plusplus */
                for (let i = 0; i < allFileTypeDocs.length; i++) {
                    const currFileId = allFileTypeDocs[i]?.id;
                    const currFileParentDocId = allFileTypeDocs[i].parentDocId;
                    // check if present in previous versions
                    let prevFlag = false;
                    for (let p = 0; p < filesInPreviousVersions.length; p++) {
                        if (currFileId === Number(filesInPreviousVersions[p]?.id)) {
                            prevFlag = true;
                            break;
                        }
                    }

                    // check if its not in current version
                    let currFlag = false;
                    if (filesInCurrentVersion && filesInCurrentVersion.length) {
                        const currVersionFilesArr = filesInCurrentVersion[0].value;
                        for (let c = 0; c < currVersionFilesArr.length; c++) {
                            if (currFileId === Number(currVersionFilesArr[c]?.id)) {
                                currFlag = true;
                                break;
                            }
                        }
                    }

                    // check for those files which were created in a doc but later removed without publishing
                    const check3 = (currFileParentDocId === documentId) && !currFlag;

                    if ((prevFlag && !currFlag) || check3) {
                        finalFileList.push(allFileTypeDocs[i]);
                    }
                }
            }
            setdrafDCOs(finalFileList);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: response.totalFiles,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    useEffect(() => {
        if (show) {
            fetchDocuments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, show]);

    return (
        <div>
            <Modal isOpen={show} className="AddToChange-Modal">
                <PlusIconSvg className="close-btn" onClick={() => handleCloseModal()} />
                <div>
                    <h1>Add an existing file:</h1>
                    <div>
                        <div className="table-section">
                            <table className="table-in-modal" {...getTableProps()}>
                                <thead className="thead-in-modal">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    {...column.getHeaderProps({
                                                        style: {
                                                            minWidth: column.minWidth
                                                                ? column.minWidth
                                                                : 'auto',
                                                        },
                                                    })}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody className="tbody-in-modal" {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <td
                                                        className="rowheight-modal"
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.column.minWidth
                                                                    ? cell.column.minWidth
                                                                    : 'auto',
                                                            },
                                                        })}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {page.length === 0 && (
                                <div className="no-draft-dcos-found">
                                    <h1>No Files Found</h1>
                                </div>
                            )}
                            {page.length > 0 && (
                                <div>
                                    <Button
                                        onClick={() => SelectAddToDCO()}
                                        disabled={isSelectLoading || selectedDocId === null}
                                        isLoading={isSelectLoading}
                                        className="submit-button add-to-change-btns"
                                        type={undefined}
                                    >
                                        Select
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddToExistingFilesModal;
