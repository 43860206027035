/* eslint-disable no-debugger */
/* eslint-disable max-lines-per-function */
import Ajv, { ValidateFunction } from 'ajv';
import qs from 'qs';
import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isValid as Validation, parse } from 'date-fns';
import {
    checkInDocument,
    getAllDocVersionIds,
    getDocument,
    getDocumentRelationsInfo,
    getDocumentTypeInfo,
    getDocumentVersion,
    getDocumentsDetails,
    getTaskDetails,
    submitDocument,
    toggleDocumentLock,
    updateApprovlStatusForTravelForm,
    updateApprovalStatus,
    updateApprovalStatusForLPNF,
    updateDocument,
    updateDocumentVersion,
    updateDocumentVersionFieldsData
} from '../API/document';
import { getDynamicValues } from '../API/fields';
import { getUserGroups } from '../API/groups';
import CONSTANTS from '../constants';
import STATIC_CONTENT from '../constants/StaticContent';
import {
    IAPIResponseStatus,
    IButtonStatus,
    IExternalApprover,
    IOptionValue,
    IReviewers,
    IUserType,
} from '../interfaces';
import { IAttachment, IAttachmentConfig, IFileTypeDoc } from '../interfaces/attachment';
import {
    IApprovalDocStage,
    IDocChecklistSteps,
    IDocReferenceInfo,
    IDocumentInfo,
    IError,
    IErrorInfo,
    IField,
    IFieldsData,
    IVersionNumberInfo,
} from '../interfaces/document';
import { IDocVersionObject, IDocumentTypeInfo } from '../interfaces/document-type';
import { IVersionOwner } from '../interfaces/user';
import {
    IDocumentApproversAction,
    IDocumentExternalApproversAction,
    documentApproversReducer,
    externalApproversReducer,
} from '../reducers/document-approver-reducer';
import { IDocumentAction, documentReducer } from '../reducers/document-reducer';
import {
    IDocumentReviewersAction,
    documentReviewersReducer,
} from '../reducers/document-reviewers-reducer';
import { useAuthDataContext } from './user-auth-provider';
import { getAttachments } from '../API/attachments';

const initialNewDocInfo: IDocumentInfo = {
    author: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    itemCreator: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    submitter: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    versionOwner: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    render: false,
    assignee: {
        employeeName: CONSTANTS.LOADING.TEXT,
        id: 0,
        email: CONSTANTS.LOADING.TEXT,
    },
    approvedVersionId: 0,
    confidential: false,
    docState: 'NEW',
    docNumber: CONSTANTS.LOADING.TEXT,
    title: CONSTANTS.LOADING.TEXT,
    description: CONSTANTS.LOADING.TEXT,
    isFavorite: false,
    approvedOn: 0,
    effectiveFrom: new Date(),
    effectiveTill: new Date(),
    isEverGreen: false,
    autoRenew: false,
    effectivePeriod: 0,
    sunsetPeriod: 0,
    activeDocumentState: 'NEW',
    documentLock: false,
};

const defaultInitialVersionValue: IDocVersionObject = {
    majorVersionNumber: 1,
    minorVersionNumber: 0,
};

const initialDocTypeInfo: IDocumentTypeInfo = {
    title: CONSTANTS.LOADING.TEXT,
    description: CONSTANTS.LOADING.TEXT,
    version: CONSTANTS.LOADING.TEXT,
    status: 'DRAFT',
    isConfidential: false,
    isContract: false,
    allowExport: false,
    isDCO: false,
    fullWidthTitleDesc: false,
    singlePageDocCreation: false,
    restrictCommentForPublished: false,
    showCommentsAfterSubmitDate: false,
    allowDCO: false,
    allowObsolete: false,
    allowRerelease: false,
    allowReviseByAnyone: false,
    allowViewingInProcessDocByAnyone: false,
    allowCustomVersioning: false,
    defaultInitialVersion: defaultInitialVersionValue,
    blockRevise: false,
    anyoneCanEditDraft: false,
    enableApprovalDeadline: false,
    defaultApprovalDeadline: 5,
    hideEventTimeline: false,
    storeAndShowActivity: false,
    allowTemplateGeneration: false,
    detailsSectionTitle: undefined,
    attachmentSectionTitle: undefined,
    approversSectionTitle: undefined,
    approverTimeStamp: undefined,
    isFileType: false,
    allowFileTypeAttachment: false,
};

const DEFAULT_ERROR: IError = {
    STEP1: {
        isValid: true,
        errorFields: [],
    },
    STEP2: {
        isValid: true,
        errorFields: [],
    },
    STEP3: {
        isValid: true,
        errorFields: [],
    },
};

const intialTripDetails = { from: '', to: '', fromDate: '', toDate: '' };

const DEFAULT_DATE_ERROR: IErrorInfo = {
    isValid: true,
    errorFields: [],
};

const initialFields: IField[] = [];
const initialFieldsData: IFieldsData[] = [];
const ajv = new Ajv();
const initialVersionOwnerData: IVersionOwner = {
    id: 0,
    email: '',
    firstName: '',
    lastname: '',
    employeeName: '',
    jobTitle: '',
    managerId: 0,
    activeUser: false,
};
const initialVersionNumberInfo: IVersionNumberInfo = {
    minorVersionNumber: 0,
    majorVersionNumber: 0,
};
interface IDocumentContext {
    documentId: number;
    selectedDocVersion: IOptionValue;
    activeDocVersionObj: IOptionValue;
    docInfo: IDocumentInfo;
    fields: IField[];
    fieldsData: IFieldsData[];
    reviewers: IReviewers[];
    approvers: IApprovalDocStage[];
    externalApprovers: IExternalApprover[];
    activeStep: IDocChecklistSteps;
    submitDocStatus: IButtonStatus;
    userType: IUserType;
    docVersions: IOptionValue[];
    docVersionsArr: any[];
    attachmentConfig: IAttachmentConfig[];
    attachments: IAttachment[];
    error: IError;
    dateErrorInfo: IErrorInfo;
    docTitleError: boolean;
    docDescError: boolean;
    docEffectiveFromError: boolean;
    docEffectiveError: boolean;
    docSunsetError: boolean;
    hideUsers: number[];
    collectComment: boolean;
    collectCommentRejection: boolean;
    docTypeInfo: IDocumentTypeInfo;
    activeDocVersionId: number;
    canViewCreateDocument: boolean;
    canViewDocumentDetails: boolean;
    hideFunctionalAreaICMP: boolean;
    hideCurrentGradeICMP: boolean;
    documentDispatch: React.Dispatch<IDocumentAction>;
    reviewersDispatch: React.Dispatch<IDocumentReviewersAction>;
    approversDispatch: React.Dispatch<IDocumentApproversAction>;
    externalApproversDispatch: React.Dispatch<IDocumentExternalApproversAction>;
    isFieldsDataSaved: boolean;
    saveFieldsData: (showToast: boolean) => void;
    updateFieldsData: () => void;
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    onDocumentSubmit: (onDocumentSubmit: boolean) => Promise<{
        status: 'SUCCESS' | 'FAILURE';
        docNumber: string;
        errorMessage?: string;
    }>;
    onDocumentCheckIn: (
        checkIn: boolean,
        docVersionId: number,
    ) => Promise<{ apiStatus: IAPIResponseStatus; data: [] | undefined } | undefined>;
    setActiveStep: React.Dispatch<React.SetStateAction<IDocChecklistSteps>>;
    setSelectedDocVersion: React.Dispatch<React.SetStateAction<IOptionValue>>;
    setActiveDocVersionObj: React.Dispatch<React.SetStateAction<IOptionValue>>;
    fetchDocumentVersion: () => Promise<void>;
    validateReferenceDocuments: () => Promise<IErrorInfo>;
    fetchDocument: () => Promise<void>;
    fetchAttachments: () => Promise<void>;
    changeApprovlStatusForTravelForm: (email: string, body: any) => Promise<void>;
    changeApprovalStatusForLPNF: (email: string) => Promise<void>;
    setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
    setHideUsers: React.Dispatch<React.SetStateAction<number[]>>;
    setHideFunctionalAreaICMP: React.Dispatch<React.SetStateAction<boolean>>;
    setHideCurrentGradeICMP: React.Dispatch<React.SetStateAction<boolean>>;
    accessibleByCurrentUser: boolean;
    isMemo: boolean;
    isLeadershipForm: boolean;
    isDCOwithApprovers: boolean;
    delegatorInfo: IOptionValue;
    taskId: string;
    publishOnceApproved: boolean;
    isSunsetEnabled: boolean;
    isPublishLimitReached: boolean;
    documentTypeId: number;
    isPRB: boolean;
    isICMP: boolean;
    isTravelAuthorizationForm: boolean;
    activeVersionOwnerInfo: IVersionOwner;
    checkInVersionOwner: IVersionOwner;
    allowViewInProcessDocFlag: boolean;
    versionNumberInfo: IVersionNumberInfo;
    submittedOn: number;
    isEndDateAsked: boolean;
    travelRequestFor: IOptionValue;
    setTravelRequestFor: React.Dispatch<React.SetStateAction<IOptionValue>>;
    isItACustomaerMeeting: boolean;
    setIsItACustomaerMeeting: React.Dispatch<React.SetStateAction<boolean>>;
    versionState: string;
    checklist: { question: string; value: null | string }[] | null;
    setchecklist: React.Dispatch<
        React.SetStateAction<{ question: string; value: null | string }[] | null>
    >;
    relationArr: any[];
    setRelationArr: React.Dispatch<React.SetStateAction<any[]>>;
    latestrelationArr: any[];
    setlatestrelationArr: React.Dispatch<React.SetStateAction<any[]>>;
    checklistErrorInfo: IErrorInfo;
    docVersionCheckedInFlag: boolean;
    setDocVersionCheckedInFlag: React.Dispatch<React.SetStateAction<boolean>>;
    activeVersionApprovalDeadline: number;
    setActiveVersionApprovalDeadline: React.Dispatch<React.SetStateAction<number>>;
    existingContract: boolean;
    setExistingContract: React.Dispatch<React.SetStateAction<boolean>>;
    disableApprovalFlow: boolean;
    setDisableApprovalFlow: React.Dispatch<React.SetStateAction<boolean>>;
    activeApprovalStatusId: number;
    showVersionModel: boolean;
    setShowVersionModel: React.Dispatch<React.SetStateAction<boolean>>;
    isGroupMember: boolean;
    setIsFieldsDataSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export const newDocumentContext = createContext<IDocumentContext>({
    hideFunctionalAreaICMP: true,
    hideCurrentGradeICMP: true,
    setHideCurrentGradeICMP: () => { },
    setHideFunctionalAreaICMP: () => { },
    documentId: 0,
    selectedDocVersion: { label: '', value: 0 },
    activeDocVersionObj: { label: '', value: 0 },
    docInfo: initialNewDocInfo,
    fields: initialFields,
    fieldsData: initialFieldsData,
    reviewers: [],
    approvers: [],
    externalApprovers: [],
    activeStep: 'STEP1',
    submitDocStatus: 'DEFAULT',
    docVersions: [],
    docVersionsArr: [],
    attachmentConfig: [],
    attachments: [],
    error: DEFAULT_ERROR,
    dateErrorInfo: DEFAULT_DATE_ERROR,
    checklistErrorInfo: DEFAULT_DATE_ERROR,
    docTitleError: false,
    docDescError: false,
    docEffectiveFromError: false,
    docEffectiveError: false,
    docSunsetError: false,
    isFieldsDataSaved: false,
    collectComment: false,
    collectCommentRejection: false,
    hideUsers: [],
    docTypeInfo: initialDocTypeInfo,
    activeDocVersionId: 0,
    canViewCreateDocument: true,
    canViewDocumentDetails: true,
    documentDispatch: () => null,
    reviewersDispatch: () => null,
    approversDispatch: () => null,
    externalApproversDispatch: () => null,
    saveFieldsData: () => null,
    updateFieldsData: () => null,
    onDocumentSubmit: () => Promise.resolve({ status: 'FAILURE', docNumber: '' }),
    onDocumentCheckIn: () => Promise.resolve({ apiStatus: 'FAILURE', data: [] }),
    setActiveStep: () => { },
    setDocInfo: () => { },
    userType: 'OTHER',
    setSelectedDocVersion: () => {},
    setActiveDocVersionObj: () => {},
    fetchDocumentVersion: () => Promise.resolve(),
    validateReferenceDocuments: () => Promise.resolve({ isValid: true, errorFields: [] }),
    fetchDocument: () => Promise.resolve(),
    fetchAttachments: () => Promise.resolve(),
    changeApprovlStatusForTravelForm: () => Promise.resolve(),
    changeApprovalStatusForLPNF: () => Promise.resolve(),
    setIsFileUploading: () => { },
    setHideUsers: () => { },
    accessibleByCurrentUser: true,
    isMemo: true,
    isPRB: false,
    isICMP: false,
    isTravelAuthorizationForm: false,
    isLeadershipForm: true,
    isDCOwithApprovers: false,
    delegatorInfo: { label: '', value: 0 },
    taskId: '',
    publishOnceApproved: false,
    isSunsetEnabled: false,
    isPublishLimitReached: false,
    activeVersionOwnerInfo: initialVersionOwnerData,
    checkInVersionOwner: initialVersionOwnerData,
    allowViewInProcessDocFlag: false,
    versionNumberInfo: initialVersionNumberInfo,
    documentTypeId: 0,
    submittedOn: 0,
    isEndDateAsked: false,
    travelRequestFor: { label: 'Self', value: 'Self' },
    setTravelRequestFor: () => { },
    isItACustomaerMeeting: false,
    setIsItACustomaerMeeting: () => { },
    versionState: 'RELEASE',
    checklist: null,
    setchecklist: () => { },
    relationArr: [],
    setRelationArr: () => { },
    latestrelationArr: [],
    setlatestrelationArr: () => { },
    docVersionCheckedInFlag: false,
    setDocVersionCheckedInFlag: () => { },
    activeVersionApprovalDeadline: 0,
    setActiveVersionApprovalDeadline: () => { },
    existingContract: false,
    setExistingContract: () => { },
    disableApprovalFlow: false,
    setDisableApprovalFlow: () => { },
    showVersionModel: false,
    setShowVersionModel: () => { },
    activeApprovalStatusId: 0,
    isGroupMember: true,
    setIsFieldsDataSaved: () => { }
});
type Props = {
    children: React.ReactNode;
}

const DocumentProvider: React.FC<Props> = ({ children }) => {
    const location = useLocation();
    const { search } = useLocation();

    let fieldsValidator: ValidateFunction;

    const [documentId, setDocumentId] = useState(0);
    const [isFieldsDataSaved, setIsFieldsDataSaved] = useState(false);
    const [activeDocVersionId, setActiveDocVersionId] = useState(0);
    const [selectedDocVersion, setSelectedDocVersion] = useState<IOptionValue>({
        label: '',
        value: 0,
    });
    const { user } = useAuthDataContext();
    const [docInfo, setDocInfo] = useState(initialNewDocInfo);
    const [isTravelAuthorizationForm, setIsTravelAuthorizationForm] = useState<boolean>(false);
    const [relationArr, setRelationArr] = useState<any[]>([]);
    const [latestrelationArr, setlatestrelationArr] = useState<any[]>([]);
    const [docTypeInfo, setDocTypeInfo] = useState(initialDocTypeInfo);
    const [fields, setFields] = useState<IField[]>([]);
    const [docVersions, setDocVersions] = useState<IOptionValue[]>([]);
    const [docVersionsArr, setDocVersionsArr] = useState<any[]>([]);
    const [activeDocVersionObj, setActiveDocVersionObj] = useState<IOptionValue>({
        label: '',
        value: 0,
    });
    const [attachmentConfig, setAttachmentConfig] = useState<IAttachmentConfig[]>([]);
    const [activeApprovalStatusId, setActiveApprovalStatusId] = useState(0);
    const [externalApprovalId, setExternalApprovalId] = useState(0);
    const [activeStep, setActiveStep] = useState<IDocChecklistSteps>('STEP1');
    const [submitDocStatus, setSubmitDocStatus] = useState<IButtonStatus>('DEFAULT');
    const [userType, setUserType] = useState<IUserType>('OTHER');
    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const [error, setError] = useState<IError>(DEFAULT_ERROR);
    const [dateErrorInfo, setdateErrorInfo] = useState<IErrorInfo>(DEFAULT_DATE_ERROR);
    const [checklistErrorInfo, setchecklistErrorInfo] = useState<IErrorInfo>(DEFAULT_DATE_ERROR);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [docTitleError, setDocTitleError] = useState(false);
    const [docEffectiveFromError, setdocEffectiveFromError] = useState(false);
    const [docDescError, setDocDescError] = useState(false);
    const [docEffectiveError, setDocEffectiveError] = useState(false);
    const [docSunsetError, setDocSunsetError] = useState(false);
    const [publishOnceApproved, setPublishOnceApproved] = useState(true);
    const [isEndDateAsked, setIsEndDateAsked] = useState(false);
    const [isSunsetEnabled, setIsSunsetEnabled] = useState(true);
    const [isPublishLimitReached, setisPublishLimitReached] = useState(false);
    const [fieldsData, documentDispatch] = useReducer(documentReducer, []);
    const [reviewers, reviewersDispatch] = useReducer(documentReviewersReducer, []);
    const [externalApprovers, externalApproversDispatch] = useReducer(externalApproversReducer, []);
    const [approvers, approversDispatch] = useReducer(documentApproversReducer, []);
    const [collectComment, setCollectComment] = useState(false);
    const [collectCommentRejection, setCollectCommentRejection] = useState(false);
    const [hideUsers, setHideUsers] = useState<number[]>([]);
    const [submittedOn, setsubmittedOn] = useState(0);
    const [canViewCreateDocument, setCanViewCreateDocument] = useState(true);
    const [canViewDocumentDetails, setCanViewDocumentDetails] = useState(true);
    const [isDocFetched, setIsDocFetched] = useState(false);
    const [accessibleByCurrentUser, setAccessibleByCurrentUser] = useState(true);
    const [isMemo, setIsMemo] = useState<boolean>(true);
    const [isPRB, setIsPRB] = useState<boolean>(false);
    const [isICMP, setIsICMP] = useState<boolean>(false);
    const [isItACustomaerMeeting, setIsItACustomaerMeeting] = useState<boolean>(false);
    const [travelRequestFor, setTravelRequestFor] = useState<IOptionValue>({
        label: 'Self',
        value: 'Self',
    });
    const [hideFunctionalAreaICMP, setHideFunctionalAreaICMP] = useState<boolean>(true);
    const [hideCurrentGradeICMP, setHideCurrentGradeICMP] = useState<boolean>(true);
    const [isLeadershipForm, setisLeadershipForm] = useState<boolean>(false);
    const [delegatorInfo, setDelegatorInfo] = useState<IOptionValue>({ label: '', value: 0 });
    const [taskId, setTaskId] = useState('');
    const [allowReviseFlag, setAllowReviseFlag] = useState(false);
    const [allowViewInProcessDocFlag, setAllowViewInProcessDocFlag] = useState(false);
    const [isGroupMember, setIsGroupMember] = useState(true);

    const [versionState, setversionState] = useState<string>('');
    const [checklist, setchecklist] = useState<{ question: string; value: null | string }[] | null>(
        null,
    );
    const [activeVersionOwnerInfo, setActiveVersionOwnerInfo] =
        useState<IVersionOwner>(initialVersionOwnerData);
    const [checkInVersionOwner, setCheckInVersionOwner] =
        useState<IVersionOwner>(initialVersionOwnerData);
    const [versionNumberInfo, setVersionNumberInfo] =
        useState<IVersionNumberInfo>(initialVersionNumberInfo);
    const [docVersionCheckedInFlag, setDocVersionCheckedInFlag] = useState<boolean>(false);
    const navigate = useNavigate();
    const [documentTypeId, setDocumentTypeId] = useState(0);
    const [checkDCOUpdate, setcheckDCOUpdate] = useState(false);
    const [isDCO, setisDCO] = useState<boolean>(false);
    const [isDCOwithApprovers, setIsDCOwithApprovers] = useState(false);
    const [activeVersionApprovalDeadline, setActiveVersionApprovalDeadline] = useState<number>(0);
    const [existingContract, setExistingContract] = useState<boolean>(false);
    const [disableApprovalFlow, setDisableApprovalFlow] = useState(false);
    const [showVersionModel, setShowVersionModel] = useState(false);
    const [flagForChildUpdate, setFlagForChildUpdate] = useState(false);

    const DCOwithApprovers = async (approversList: IApprovalDocStage[], prefixCode: any) => {
        if (prefixCode.includes('DCO')) {
            let approverscount = 0;
            // eslint-disable-next-line array-callback-return
            approversList.map((stage: any) => {
                if (stage?.approvers.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    stage?.approvers.map((stageApprovers: any) => {
                        if (stageApprovers?.employee) approverscount += 1;
                        if (stageApprovers?.adHoc.length > 0)
                            approverscount += stageApprovers?.adHoc.length;
                    });
                }
            });
            if (approverscount > 1) {
                setIsDCOwithApprovers(true);
            }
        }
    };

    async function getDocumentRelations() {
        if (documentId && selectedDocVersion.value) {
            const res = await getDocumentRelationsInfo(Number(selectedDocVersion.value));
            if (res?.apiStatus === 'SUCCESS') {
                setRelationArr(res?.data);
                if (activeDocVersionId === selectedDocVersion.value)
                    setlatestrelationArr(res?.data);
            }
        }
    }

    async function getLatestDocumentRelations() {
        if (activeDocVersionId !== selectedDocVersion.value) {
            const res = await getDocumentRelationsInfo(activeDocVersionId);
            if (res?.apiStatus === 'SUCCESS') {
                setlatestrelationArr(res?.data);
            }
        }
    }

    useEffect(() => {
        if (docTypeInfo && docTypeInfo.allowDCO) {
            getDocumentRelations();
        }
    }, [docTypeInfo]);

    useEffect(() => {
        if (activeDocVersionId && docTypeInfo && docTypeInfo.allowDCO) getLatestDocumentRelations();
    }, [activeDocVersionId]);

    const makeFieldMandatory = (fieldName: string) => {
        setFields((perviousData) => {
            const index = perviousData.findIndex((field) => field.name === fieldName);
            if (index !== -1) {
                const newData = [...perviousData];
                newData[index].mandatory = true;
                return newData;
            }
            return perviousData;
        });
    };

    const makeFieldNotMandatory = (fieldName: string) => {
        setFields((perviousData) => {
            const index = perviousData.findIndex((field) => field.name === fieldName);
            if (index !== -1) {
                const newData = [...perviousData];
                newData[index].mandatory = false;
                return newData;
            }
            return perviousData;
        });
    };

    const fetchDocumentVersion = async () => {
        const isDocumentDetails = location.pathname === '/document-details';
        if (documentId && selectedDocVersion.value) {
            const response = await getDocumentVersion(
                documentId,
                selectedDocVersion.value as number,
                user.id,
                allowReviseFlag,
                isDocumentDetails,
            );
            if (response) {
                // create IField data for file type field
                // if(response?.docTypeInfo?.allowFileTypeAttachment) {
                //     const fileTypeAttachmentFieldObj = {
                //         "id":296,
                //         "name":"documents",
                //         "helperText":"Link Documents",
                //         "displayName":"Documents",
                //         "dataType":{
                //             "label":"document",
                //             "value":7,
                //             "uiControls":[
                //             {
                //                 "label":"Table",
                //                 "value":11
                //             },
                //             {
                //                 "label":"Reference",
                //                 "value":12
                //             }
                //             ]
                //         },
                //         "uiControl":{
                //             "label":"Table",
                //             "value":11
                //         },
                //         "mandatory":true,
                //         "selectType":null,
                //         "selectOption":[

                //         ],
                //         "markupText":"",
                //         "docTypeOptionValues":null,
                //         "docTypeFieldsOptionValues":null,
                //         "isLargeData":false,
                //         "readOnly":false
                //     }
                // }
                const fieldsTempData: IFieldsData[] = [];
                // eslint-disable-next-line no-restricted-syntax
                for (const field of response.fields) {
                    let value: unknown = CONSTANTS.DEFAULT_VALUE[field.dataType.label];
                    if (response.data[field.name]) {
                        if (field.selectType === 'dynamic') {
                            if (field.uiControl.label === 'Single Select Dropdown') {
                                if ((response.data[field.name] as string[]).length > 0) {
                                    // eslint-disable-next-line no-await-in-loop
                                    const dynamicOptions = await getDynamicValues(
                                        field.id,
                                        '',
                                        response.data[field.name] as string[],
                                    );
                                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                                        value = dynamicOptions.dynamicData;
                                    } else {
                                        toast.error(CONSTANTS.ERROR_406);
                                    }
                                }
                            }
                            if (field.uiControl.label === 'Multi Select Dropdown') {
                                if ((response.data[field.name] as string[]).length > 0) {
                                    // eslint-disable-next-line no-await-in-loop
                                    const dynamicOptions = await getDynamicValues(
                                        field.id,
                                        '',
                                        response.data[field.name] as string[],
                                    );
                                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                                        value = dynamicOptions.dynamicData;
                                    } else {
                                        toast.error(CONSTANTS.ERROR_406);
                                    }
                                }
                            }
                        } else if (field.selectType === 'static') {
                            if (field.uiControl.label === 'Single Select Dropdown') {
                                // value = {
                                //     label: response.data[field.name],
                                //     value: response.data[field.name],
                                // };
                                // value = response.data[field.name];
                                value = (response.data[field.name] as string[]).map((o) => ({
                                    label: o,
                                    value: o,
                                }));
                            }
                            if (field.uiControl.label === 'Multi Select Dropdown') {
                                value = (response.data[field.name] as string[]).map((o) => ({
                                    label: o,
                                    value: o,
                                }));
                            }
                        } else if (field.uiControl.label === 'Travel Form') {
                            if (Array.isArray(response.data[field.name])) {
                                value = {
                                    travelType: "Round Trip",
                                    travelDetails: response.data[field.name],
                                }
                            } else {
                                value = response.data[field.name];
                            }
                        } else if (response.data[field.name]) {
                            value = response.data[field.name];
                        }
                    } else if (field.dataType.label === 'date-time' && field.uiControl.label === 'OnlyDate') {
                        value = '';
                    } else if (field.uiControl.label === 'Travel Form') {
                        if (Array.isArray(value)) {
                            value = {
                                travelType: "Round Trip",
                                travelDetails: value.length > 0 ? value : [{ ...intialTripDetails }],
                            }
                        }
                    }
                    fieldsTempData.push({
                        name: field.name,
                        value,
                    });
                }
                const { prefixes } = response;
                if (prefixes.length > 0) {
                    const temp = prefixes.some(
                        (item) => item.type === 'static' && item.value === 'MEMO',
                    );
                    const check = prefixes.some(
                        (item) =>
                            item.type === 'static' &&
                            (item.value === 'ICMP' || item.value === 'LPNF'),
                    );
                    const isLeadershipFormCheck = prefixes.some(
                        (item) =>
                            item.type === 'static' &&
                            (item.value === 'LPNF' || item.value === 'HRF'),
                    );
                    const isDCOCheck = prefixes.some(
                        (item) => item.type === 'static' && item.value.includes('DCO'),
                    );
                    const checkICMP = prefixes.some(
                        (item) => item.type === 'static' && item.value === 'ICMP',
                    );
                    const checkTravelForm = prefixes.some(
                        (item) => item.type === 'static' && item.value === 'TRAP',
                    );
                    setIsICMP(checkICMP);
                    setisDCO(isDCOCheck);
                    setIsMemo(temp);
                    setIsPRB(check);
                    setisLeadershipForm(isLeadershipFormCheck);
                    setIsTravelAuthorizationForm(checkTravelForm);
                    if (checkTravelForm) {
                        const customerMeetingField = fieldsTempData.filter(
                            (item) => item.name === 'customerMetting',
                        );
                        if (customerMeetingField.length > 0) {
                            setIsItACustomaerMeeting(customerMeetingField[0].value);
                        }
                        const travelRequestForField = fieldsTempData.filter(
                            (item) => item.name === 'travelRequestFor',
                        );
                        if (travelRequestForField.length > 0) {
                            setTravelRequestFor(travelRequestForField[0].value[0]);
                        }
                    }
                }

                // console.log(`-- fetched doc version data: ${JSON.stringify(response)}`)
                setAttachmentConfig(response.attachmentConfig);
                documentDispatch({ type: 'push', value: fieldsTempData });
                if (response?.data?.file) {
                    documentDispatch({
                        type: 'file-list',
                        value: response?.data?.file as IFileTypeDoc[],
                        inputName: 'file',
                    });
                }
                reviewersDispatch({ type: 'update', value: response.reviewers });
                approversDispatch({
                    type: 'update',
                    value: response.approvers,
                });
                externalApproversDispatch({
                    type: 'update',
                    value: response.externalApprovers,
                });
                await DCOwithApprovers(response.approvers, response.docTypeInfo?.prefixCode);
                setsubmittedOn(response.submittedOn);
                setActiveApprovalStatusId(response.activeApprovalStatusId);
                setExternalApprovalId(response.externalApprovalId);
                setDocInfo(response.docInfo);
                setFields(response.fields);
                setUserType(response.userType);
                setActiveVersionOwnerInfo(response.versionOwner);
                setCheckInVersionOwner(response.checkInVersionOwner);
                setVersionNumberInfo(response.versionNumberInfo);
                setversionState(response.versionState);
                setchecklist(response.checklist);
                setDocTypeInfo(response.docTypeInfo);
                if(!response.docTypeConfig.isDCO) {
                    setHideUsers([...response.hideUsers, user.id]);
                }
                setCollectComment(response.collectComment);
                setCollectCommentRejection(response.collectCommentRejection);
                setAccessibleByCurrentUser(response.accessibleByCurrentUser);
                setTaskId(response.taskId);
                setPublishOnceApproved(response.docTypeConfig.publishOnceApproved);
                setIsEndDateAsked(response.docTypeConfig.isEndDateAsked);
                setIsSunsetEnabled(response.docTypeConfig.isSunsetEnabled);
                setDocumentTypeId(response.documentTypeId);
                setActiveVersionApprovalDeadline(
                    response?.approvalDeadline
                        ? response?.approvalDeadline
                        : response.docTypeInfo.defaultApprovalDeadline,
                );
                setExistingContract(response?.existingContract);
                setDisableApprovalFlow(response.disableApprovalFlow);
                // console.log(`in fetchDocumentVersion fn, ***res: ${JSON.stringify(response)}, &&&& apprDeadline: ${response.approvalDeadline}`);
                const { maxRepublishCount } = response.docTypeConfig;
                const { republishCount } = response;
                if (republishCount >= maxRepublishCount) setisPublishLimitReached(true);
                if (!ajv.getSchema(response.schema.$id)) {
                    ajv.addSchema(response.schema);
                }
                fieldsValidator = ajv.getSchema(response.schema.$id) || ajv.compile(response.schema);
                if (
                    !(
                        response.userType === 'CREATOR' &&
                        (response.docInfo.docState === 'DRAFT' ||
                            response.docInfo.docState === 'REJECTED') &&
                        Number(response.versionOwner.id) === user.id
                    )
                ) {
                    setCanViewCreateDocument(false);
                }
                if (isItACustomaerMeeting) {
                    makeFieldMandatory('customerName');
                } else {
                    makeFieldNotMandatory('customerName');
                }
                setcheckDCOUpdate(true);
            }
        }
    };

    function isObject(obj: any) {
        return obj != null && typeof obj === 'object';
    }

    function deepEqual(object1: any, object2: any) {
        const keys2 = Object.keys(object2);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys2) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (
                (areObjects && !deepEqual(val1, val2)) ||
                // eslint-disable-next-line eqeqeq
                (!areObjects && val1 != val2)
            ) {
                return false;
            }
        }

        return true;
    }

    const updateFieldsDatainDCO = async (fieldsTempData: IFieldsData[]) => {
        documentDispatch({ type: 'push', value: fieldsTempData });
    }

    const checkForDCOUpdates = async (fieldsTemporaryData: IFieldsData[]) => {
        const fieldsTempData = fieldsTemporaryData;
        const documentsArr = fieldsTempData.find((obj) => obj.name === 'documents')?.value;
        if (documentsArr.length > 0) {
            const docIds: number[] = [];
            documentsArr?.map((obj: any) => docIds.push(obj.id));
            const res = await getAllDocVersionIds(docIds);
            if (res) {
                const docVersionIds = res ? res?.documentVersionIds : [];
                const response = await getDocumentsDetails(docVersionIds);
                if (response?.apiStatus === 'SUCCESS') {
                    const data: any[] = [];
                    const responseData1 = response.data;
                    let flag = true;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const id of docIds) {
                        const docObjIndex = responseData1.findIndex((obj) => obj?.id === id);
                        const documentsArrObj = documentsArr.find((obj: any) => obj?.id === id);
                        if (docObjIndex === -1) {
                            toast.info(
                                `document ${documentsArrObj.documentNumber} has been discarded, removing it from DCO`,
                            );
                            flag = false;
                        } else {
                            data.push(responseData1?.[docObjIndex]);
                            const flaghere = deepEqual(responseData1[docObjIndex], documentsArrObj);
                            if (
                                responseData1[docObjIndex].activeVersionId <
                                documentsArrObj.activeVersionId
                            ) {
                                toast.info(
                                    `document ${documentsArrObj.documentNumber} has been reverted to older version, updating details in DCO`,
                                );
                            } else if (
                                responseData1[docObjIndex].activeVersionId >
                                documentsArrObj.activeVersionId
                            ) {
                                toast.info(
                                    `document ${documentsArrObj.documentNumber} has been revised, updating details in DCO`,
                                );
                            } else if (!flaghere) {
                                if (
                                    responseData1[docObjIndex].activeDocumentVersion.checkedIn ===
                                    documentsArrObj.activeDocumentVersion.checkedIn
                                )
                                    toast.info(
                                        `document ${documentsArrObj.documentNumber} has been modified, updating its details in DCO`,
                                    );
                                else if (
                                    responseData1[docObjIndex].activeDocumentVersion.checkedIn ===
                                    false
                                ) {
                                    toast.info(
                                        `document ${documentsArrObj.documentNumber} has been checked out`,
                                    );
                                } else {
                                    toast.info(
                                        `document ${documentsArrObj.documentNumber} has been checked in`,
                                    );
                                }
                            }
                            if (!flaghere) flag = false;
                        }
                    }
                    if (!flag) {
                        setFlagForChildUpdate(true);
                        const index = fieldsTempData.findIndex((obj) => obj.name === 'documents');
                        if (index !== -1) {
                            fieldsTempData[index].value = data;
                        }
                        await updateFieldsDatainDCO(fieldsTempData);
                        documentDispatch({ type: 'push', value: fieldsTempData });
                        const responseData = await updateDocumentVersion(
                            fieldsTempData,
                            documentId,
                            selectedDocVersion.value as number,
                            activeApprovalStatusId,
                            isEndDateAsked,
                            reviewers,
                            approvers,
                            checklist,
                            externalApprovalId,
                            externalApprovers,
                            docInfo.author.id,
                            docTypeInfo.allowReviseByAnyone,
                            docInfo.isEverGreen,
                            docInfo.autoRenew,
                            activeVersionApprovalDeadline,
                            existingContract,
                            docInfo.effectiveFrom,
                            docInfo.effectiveTill,
                            docInfo.effectivePeriod * 604800,
                            isEndDateAsked
                                ? docInfo.sunsetPeriod * 86400
                                : docInfo.sunsetPeriod * 604800,
                        );
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (flagForChildUpdate) {
            setFlagForChildUpdate(false);
        }
    }, [flagForChildUpdate])

    // const MINUTE_MS = 300000;

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const isCreateDocument = history.location.pathname === '/create-document';
    //         if (isDCO && isCreateDocument && fieldsData.length > 0) {
    //             checkForDCOUpdates(fieldsData);
    //         }
    //     }, MINUTE_MS);

    //     return () => clearInterval(interval);
    // }, [isDCO, fieldsData]);

    useEffect(() => {
        if (isDCO && fieldsData.length > 0 && checkDCOUpdate && docInfo.docState !== 'PUBLISHED') {
            checkForDCOUpdates(fieldsData);
        }
    }, [checkDCOUpdate]);

    const updateFieldsData = async () => {
        setIsFieldsDataSaved(true);
        await updateDocumentVersionFieldsData(
            fieldsData,
            documentId,
            selectedDocVersion.value as number,
        );
        setIsFieldsDataSaved(false);
    };

    const saveFieldsData = async (showToast = true) => {
        setIsFieldsDataSaved(true);
        if (docInfo.activeDocumentState === 'UNDER_REVIEW') {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const referenceValidation = await validateReferenceDocuments();
            if (!referenceValidation.isValid) {
                toast.error('Failed to save document info');
                setIsFieldsDataSaved(false);
                return;
            }
        }
        const docUpdateRes = await updateDocument(
            docInfo.title,
            docInfo.description,
            docInfo.confidential,
            docInfo.documentLock,
            documentId,
            docTypeInfo.allowReviseByAnyone,
        );

        if (docUpdateRes?.apiStatus === 'SUCCESS') {
            const response = await updateDocumentVersion(
                fieldsData,
                documentId,
                selectedDocVersion.value as number,
                activeApprovalStatusId,
                isEndDateAsked,
                reviewers,
                approvers,
                checklist,
                externalApprovalId,
                externalApprovers,
                docInfo.author.id,
                docTypeInfo.allowReviseByAnyone,
                docInfo.isEverGreen,
                docInfo.autoRenew,
                activeVersionApprovalDeadline,
                existingContract,
                docInfo.effectiveFrom,
                docInfo.effectiveTill,
                docInfo.effectivePeriod * 604800,
                isEndDateAsked ? docInfo.sunsetPeriod * 86400 : docInfo.sunsetPeriod * 604800,
            );
            if (response?.apiStatus === 'SUCCESS') {
                // index on save
                // await indexDocumentOnSave(latestDocTypeVersionId, documentId, docInfo.docNumber, docInfo.confidential);

                const childDocIds: number[] = [];
                fieldsData.forEach((fieldData) => {
                    if (fieldData.name === 'documents') {
                        fieldData.value.forEach((currDoc: IDocReferenceInfo) => {
                            childDocIds.push(currDoc.id);
                        });
                    }
                });
                if (childDocIds.length) {
                    await Promise.all(
                        childDocIds.map(async (currId) => {
                            await toggleDocumentLock(true, Number(currId), documentId);
                        }),
                    );
                }

                await fetchDocumentVersion();
                if (showToast) toast.success('Saved successfully...');
            } else if (response?.apiStatus === 'FAILURE') {
                toast.error(response.errorMessage);
            } else if (response?.apiStatus === 'UNAUTHORIZED') {
                toast.error(STATIC_CONTENT.DOCUMENT_ACTIONS.TOAST.ERROR_MSG.UNAUTHORIZED);
            }
            setIsFieldsDataSaved(false);
        } else if (docUpdateRes?.apiStatus === 'FAILURE') {
            toast.error('Failed to save document info');
            setIsFieldsDataSaved(false);
        } else if (docUpdateRes?.apiStatus === 'UNAUTHORIZED') {
            toast.error(STATIC_CONTENT.DOCUMENT_ACTIONS.TOAST.ERROR_MSG.UNAUTHORIZED);
            setIsFieldsDataSaved(false);
        }
    };

    const fieldsValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        const data: {
            [key: string]: unknown;
        } = {};
        // else if(field.dataType.label === 'date-time' && field.uiControl.label === 'OnlyDate'){
        //     value  = format(value as Date, 'dd/MM/yyyy');
        // }
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            const [field] = fields.filter((f) => f.name === fieldData.name);
            if (field && field.mandatory) {
                if (
                    field.uiControl.label === 'Multi Select Dropdown' ||
                    field.uiControl.label === 'Single Select Dropdown'
                ) {
                    const fieldDataArray = fieldData.value;
                    const objToArr = Object.keys(fieldDataArray);
                    objToArr.forEach((idx) => {
                        fieldDataArray[idx] = fieldDataArray[idx].value;
                    });

                    data[fieldData.name] = fieldDataArray;
                } else {
                    data[fieldData.name] = fieldData.value;
                }
            }
        }
        const valid = fieldsValidator(data);
        if (!valid) {
            isValid = false;
            if (fieldsValidator?.errors) {
                // eslint-disable-next-line no-restricted-syntax
                for (const errorType of fieldsValidator.errors) {
                    let field = errorType.instancePath.split('/')[1];
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const params = errorType.params as any;
                    if (!field) {
                        field = params?.missingProperty ? params?.missingProperty : '';
                    }
                    errorFields.push(field);
                }
            }
        }

        return { isValid, errorFields };
    };
    const dateValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            const [field] = fields.filter((f) => f.name === fieldData.name);
            if (
                field &&
                field.dataType.label === 'date-time' &&
                field.uiControl.label === 'OnlyDate'
            ) {
                if (fieldData.value === '') {
                    isValid = true;
                } else {
                    const [month, day, year] = fieldData.value.split('/');
                    const parsedDate = parse(`${day}/${month}/${year}`, 'dd/MM/yyyy', new Date());
                    if (!Validation(parsedDate)) {
                        isValid = false;
                        errorFields.push(fieldData.name);
                    }
                }
            }
        }
        return { isValid, errorFields };
    };

    const checklistValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        if (Array.isArray(checklist)) {
            // eslint-disable-next-line array-callback-return
            checklist?.map((item) => {
                if (item.value === null) {
                    isValid = false;
                    errorFields.push(item.question);
                }
            });
        }
        return { isValid, errorFields };
    };

    const validateReferenceDocuments = async (): Promise<IErrorInfo> => {
        let isValid = true;
        const errorFields: string[] = [];
        let fieldName = '';
        let referenceDocuments: [] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            const [field] = fields.filter((f) => f.name === fieldData.name);
            if (field && field.dataType.label === 'document' && field.uiControl.label === 'Table') {
                fieldName = fieldData.name;
                referenceDocuments = fieldData.value;
            }
        }
        // const refDocumentDetails = referenceDocuments.map(async (reference) => await getDocumentsDetails(reference.activeDocumentVersion.id));
        const refDocumentDetailsPromises = referenceDocuments.map((reference: IDocReferenceInfo) =>
            getDocumentsDetails([reference.activeDocumentVersion.id]),
        );
        const refDocumentDetails = await Promise.all(refDocumentDetailsPromises);
        // eslint-disable-next-line no-restricted-syntax
        for (const res of refDocumentDetails) {
            const doc: IDocReferenceInfo[] = referenceDocuments.filter(
                (f: IDocReferenceInfo) => f.id === res?.data[0].id,
            );
            if (res?.data[0].state !== 'DRAFT') {
                isValid = false;
                toast.error(`There is no unreleased version of ${res?.data[0].documentNumber}`);
            }

            // if (res?.data[0].documentLock && docInfo.docState === "DRAFT") {
            //     isValid = false;
            //     if (res.data[0].parentDCOInfo) {
            //         setlatestrelationArr(res.data[0].parentDCOInfo);
            //     }
            //     if (latestrelationArr.length) {
            //         toast.error(
            //             `${res.data[0].documentNumber} is already locked by ${latestrelationArr[0].documentNumber}`,
            //         );
            //     }
            // }

            if (!res?.data[0].activeDocumentVersion.checkedIn) {
                isValid = false;
                toast.error(
                    `Please check in the document ${res?.data[0].documentNumber} before submitting the DCO`,
                );
            }
            if (res?.data[0].activeVersionId !== doc[0].activeVersionId) {
                isValid = false;
                toast.error(
                    `Please remove outdated version of ${res?.data[0].documentNumber} and add latest version`,
                );
            }
        }
        // console.log(isValid);
        if (!isValid) {
            errorFields.push(fieldName);
        }
        return { isValid, errorFields };
    };

    const validateAttachedFileTypeDocuments = async (): Promise<IErrorInfo> => {
        const isValid = true;
        const errorFields: string[] = [];
        const fieldName = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            if (fieldData.name === 'file') {
                // add validations here
            }
        }
        // console.log(isValid);
        if (!isValid) {
            errorFields.push(fieldName);
        }
        return { isValid, errorFields };
    };

    const attachmentValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        const [filesData] = fieldsData.filter((a) => a.name === 'file');
        if (filesData && filesData.value.length) {
            return { isValid, errorFields };
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const config of attachmentConfig) {
            const q1 = { question: 'Redline Document attached?', value: null };
            let RedlineMandatory = false;
            if (Array.isArray(checklist)) {
                const item1 = checklist.find((item) => item.question === q1.question);
                if (item1) {
                    const { value } = item1;
                    if (value === null || value === 'no') {
                        RedlineMandatory = false;
                    } else RedlineMandatory = true;
                }
            }
            if (config.isMandatory || RedlineMandatory) {
                const uploaded = attachments.filter(
                    (attachment) => attachment.fileMeta.attachmentType === config.attachmentType,
                );
                if (uploaded.length === 0) {
                    isValid = false;
                    errorFields.push(config.attachmentType);
                }
            }
        }

        return { isValid, errorFields };
    };

    const workflowValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const approverKey in approvers) {
            if (
                approvers[approverKey].mandatory &&
                approvers[approverKey].approvers.length === 0 &&
                !approvers[approverKey].exempt
            ) {
                isValid = false;
                errorFields.push(approverKey);
            }
        }

        return { isValid, errorFields };
    };

    const validateDocument = async (isDCOChild: boolean): Promise<boolean> => {
        let isValid = true;
        if (isFileUploading) {
            toast.warn('Submit after file upload');
            isValid = false;
        }
        if (!docInfo.title) {
            setDocTitleError(true);
            isValid = false;
        }
        if (!docInfo.description) {
            setDocDescError(true);
            isValid = false;
        }
        if (!docInfo.isEverGreen) {
            if (!docInfo.effectivePeriod && isSunsetEnabled && !isEndDateAsked) {
                setDocEffectiveError(true);
                isValid = false;
            }
            if (!docInfo.sunsetPeriod && isSunsetEnabled && !isEndDateAsked) {
                setDocSunsetError(true);
                isValid = false;
            }
            if (!docInfo.sunsetPeriod && isSunsetEnabled && isEndDateAsked && docInfo.autoRenew) {
                setDocSunsetError(true);
                isValid = false;
            }
            if (docInfo.sunsetPeriod > docInfo.effectivePeriod && !isEndDateAsked) {
                setDocSunsetError(true);
                isValid = false;
            }
            if (isEndDateAsked) {
                const effectiveFromTime = Math.floor(
                    new Date(docInfo.effectiveFrom).getTime() / 1000,
                );
                const effectiveTillTime = Math.floor(
                    new Date(docInfo.effectiveTill).getTime() / 1000,
                );
                if (docInfo.effectiveTill < docInfo.effectiveFrom) {
                    setDocEffectiveError(true);
                    isValid = false;
                } else if (
                    effectiveTillTime - effectiveFromTime < docInfo.sunsetPeriod * 86400 &&
                    docInfo.autoRenew
                ) {
                    setDocSunsetError(true);
                    isValid = false;
                }
            }
        }
        // if (!docInfo.effectiveFrom && !publishOnceApproved) {
        //     setdocEffectiveFromError(true);
        //     isValid = false;
        // }
        const errorData = DEFAULT_ERROR;
        const field = fieldsValidation();

        const attachment = attachmentValidation();
        let workflow: IErrorInfo = {
            isValid: true,
            errorFields: [],
        };
        if (!isDCOChild) {
            workflow = workflowValidation();
        }
        const validDates = dateValidation();
        const referenceValidation = await validateReferenceDocuments();
        const validateChecklist = checklistValidation();
        if (!field.isValid) {
            isValid = false;
        }
        if (!referenceValidation.isValid) {
            isValid = false;
        }
        if (!attachment.isValid) {
            isValid = false;
        }
        if (!workflow.isValid) {
            isValid = false;
        }
        if (!validDates.isValid) {
            isValid = false;
        }
        if (!validateChecklist.isValid) {
            isValid = false;
        }
        errorData.STEP1 = field;
        errorData.STEP2 = attachment;
        errorData.STEP3 = workflow;
        setError(errorData);
        setdateErrorInfo(validDates);
        setchecklistErrorInfo(validateChecklist);
        return isValid;
    };

    const onDocumentSubmit = async (notifyTravelAdmin: boolean): Promise<{
        status: 'SUCCESS' | 'FAILURE';
        docNumber: string;
        errorMessage?: string;
    }> => {
        setSubmitDocStatus('LOADING');
        await saveFieldsData(false);
        const valid = await validateDocument(false);
        if (valid) {
            const response = await submitDocument(documentId, user.id, notifyTravelAdmin);
            if (response?.apiStatus === 'SUCCESS') {
                // lock dco children if any
                // const childDocIds: number[] = [];
                // fieldsData.forEach((fieldData) => {
                //     if (fieldData.name === 'documents') {
                //         fieldData.value.forEach((currDoc: IDocReferenceInfo) => {
                //             childDocIds.push(currDoc.id);
                //         });
                //     }
                // });

                // if (childDocIds.length) {
                //     await Promise.all(
                //         childDocIds.map(async (currId) => {
                //             await toggleDocumentLock(true, Number(currId));
                //         }),
                //     );
                // }
                return { status: 'SUCCESS', docNumber: response.docNumber };
            }
            if (response?.docTypeChanged) {
                window.location.reload();
            }
            if (response?.apiStatus === 'FAILURE') {
                toast.error(response.errorMessage);
            }
        } else {
            toast.error("Some mandatory Fields are missing, please update and proceed");
            setSubmitDocStatus('DEFAULT');
            return { status: 'FAILURE', docNumber: '', errorMessage: 'Invalid Document' };
        }
        setSubmitDocStatus('DEFAULT');
        return { status: 'FAILURE', docNumber: '' };
    };

    const onDocumentCheckIn = async (
        checkIn: boolean,
        docVersionId: number,
    ): Promise<
        | {
            apiStatus: IAPIResponseStatus;
            data: [] | undefined;
        }
        | undefined
    > => {
        let valid = false;
        if (checkIn) {
            valid = await validateDocument(true);
        }
        if (valid || !checkIn) {
            if (docTypeInfo.allowDCO && checkIn && docVersions.length >= 2) {
                const currentMinorVersion = versionNumberInfo.minorVersionNumber;
                const currentMajorVersion = versionNumberInfo.majorVersionNumber;
                const approvedVersion = docVersions[docVersions.length - 2]?.label;
                const [approvedMajorStr, approvedMinorStr] = approvedVersion.split('.');
                const approvedMajorVersion = parseInt(approvedMajorStr, 10);
                const approvedMinorVersion = parseInt(approvedMinorStr, 10);
                if (
                    currentMajorVersion === approvedMajorVersion &&
                    currentMinorVersion === approvedMinorVersion
                ) {
                    setShowVersionModel(true);
                    return undefined;
                }
            }

            const response = await checkInDocument(checkIn, docVersionId, relationArr);
            if (response?.apiStatus === 'SUCCESS') {
                return { apiStatus: 'SUCCESS', data: response.data };
            }
        }
        if (!valid) {
            return { apiStatus: 'FAILURE', data: undefined };
        }
        return { apiStatus: 'FAILURE', data: [] };
    };

    useEffect(() => {
        fetchDocumentVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocVersion]);

    function useQuery() {
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    const fetchDocument = async () => {
        if (documentId) {
            const res = await getDocumentTypeInfo(documentId);
            const flag = res?.docTypeConfig.allowReviseByAnyone
                ? res?.docTypeConfig.allowReviseByAnyone
                : false;
            const readFlag = res?.docTypeConfig.allowViewingInProcessDocByAnyone
                ? res?.docTypeConfig.allowViewingInProcessDocByAnyone
                : false;
            setAllowReviseFlag(flag);
            setAllowViewInProcessDocFlag(readFlag);
            const isDocumentDetails = location.pathname === '/document-details';
            const response = await getDocument(
                documentId,
                flag,
                isDocumentDetails,
                res?.docTypeConfig.allowDCO,
            );
            if (response?.apiStatus === 'SUCCESS') {
                setDocVersions(response.documentInfo.versions);
                setDocVersionsArr(response.documentVersions);
                setDocVersionCheckedInFlag(
                    response.documentInfo.activeDocumentVersionCheckedInFlag,
                );
                // Incase the document is selected through Search Bar , it should show approvedDocument Version
                if (
                    ((location.search.includes('prev_page_type=SEARCH') ||
                        flag ||
                        readFlag) && !location.search.includes('latestVersion=true') &&
                        (response.documentInfo.state === 'DRAFT' ||
                            response.documentInfo.state === 'UNDER_REVIEW' ||
                            response.documentInfo.state === 'REJECTED') &&
                        location.pathname !== '/create-document' &&
                        user.id !== response.documentInfo.activeDocumentVersionOwnerId) ||
                    res?.docTypeConfig?.isFileType
                ) {
                    const selectedVersion = response.documentInfo.versions.filter(
                        (version) =>
                            version.value === response.documentInfo.approvedDocumentVersion,
                    );
                    if (selectedVersion.length > 0) {
                        setSelectedDocVersion(selectedVersion[0]);
                    } else {
                        setSelectedDocVersion(response.documentInfo.activeDocumentVersion);
                    }
                } else {
                    setSelectedDocVersion(response.documentInfo.activeDocumentVersion);
                }
                if (response.documentVersions && response.documentVersions.length > 0) {
                    const { activeDocumentVersion } = response.documentInfo;
                    const versionToUse = response.documentVersions.find((version: any) => version.id === activeDocumentVersion.value);

                    if (versionToUse) {
                        const { id, majorVersionNumber, minorVersionNumber, state } = versionToUse;
                        const obj = {
                            value: id,
                            label: `${majorVersionNumber}.${minorVersionNumber} - ${state}`
                        };
                        setActiveDocVersionObj(obj);
                    }
                }
                if (query.get('docVersionId')) {
                    const selectedVersion = response.documentInfo.versions.filter(
                        (version) => version.value === Number(query.get('docVersionId')),
                    );
                    if (selectedVersion.length > 0) {
                        setSelectedDocVersion(selectedVersion[0]);
                    }
                    // setSelectedDocVersion(state.activeVersionId)
                }
                setActiveDocVersionId(response.documentInfo.activeDocumentVersion.value as number);
                setIsDocFetched(true);
                setAttachments([]);
            } else if (response?.apiStatus === 'UNAUTHORIZED') {
                setCanViewDocumentDetails(false);
                setIsDocFetched(false);
            } else {
                toast('Something went wrong while fetching document details');
                setIsDocFetched(false);
            }
        }
    };

    const changeApprovlStatusForTravelForm = async (email: string, body: any) => {
        const res = await updateApprovlStatusForTravelForm(email, documentId, body);
        if (res) {
            if (documentDispatch) {
                documentDispatch({ type: 'text', inputName: 'department', value: res.department });
            }
            const isDocumentDetails = location.pathname === '/document-details';
            const response = await getDocumentVersion(
                documentId,
                selectedDocVersion.value as number,
                user.id,
                allowReviseFlag,
                isDocumentDetails,
            );
            if (response) {
                approversDispatch({
                    type: 'update',
                    value: response.approvers,
                });
            }
        }
        // await saveFieldsData(false);
    };

    const changeApprovalStatusForLPNF = async (email: string) => {
        const res = await updateApprovalStatusForLPNF(email, documentId);
        if (res) {
            const isDocumentDetails = location.pathname === "/document-details";
            const response = await getDocumentVersion(
                documentId,
                selectedDocVersion.value as number,
                user.id,
                allowReviseFlag,
                isDocumentDetails,
            );
            if (response) {
                approversDispatch({
                    type: 'update',
                    value: response.approvers,
                });
            }
        }
    }

    useEffect(() => {
        if (isItACustomaerMeeting) {
            makeFieldMandatory('customerName');
        } else {
            makeFieldNotMandatory('customerName');
        }
    }, [isItACustomaerMeeting]);

    useEffect(() => {
        if (
            travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.OTHER_EMPLOYEE
        ) {
            makeFieldMandatory('traveller');
            makeFieldNotMandatory('guestName');
        } else if (travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.GUEST) {
            makeFieldMandatory('guestName');
            makeFieldNotMandatory('traveller');
        } else if (travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.SELF) {
            makeFieldNotMandatory('traveller');
            makeFieldNotMandatory('guestName');
        }
    }, [travelRequestFor]);

    useEffect(() => {
        fetchDocument();
    }, [documentId]);

    const fetchAttachments = async () => {
        const res = await getAttachments(
            selectedDocVersion.value as number,
            docTypeInfo.allowReviseByAnyone,
        );
        if (res?.apiStatus === 'SUCCESS') {
            setAttachments(res.attachments);
        }
    };

    const fetchApprovers = async () => {
        if (taskId) {
            const res = await getTaskDetails(taskId, user.id);
            if (res) {
                approversDispatch({ type: 'update', value: res.approvers });
                await DCOwithApprovers(res.approvers, res.docTypeInfo?.prefixCode);
            }
        }
    };

    useEffect(() => {
        if (docTypeInfo.isConfidential) {
            setDocInfo({ ...docInfo, confidential: true });
        }
    }, [docTypeInfo.isConfidential]);

    useEffect(() => {
        if (docTitleError) {
            setDocTitleError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.title]);

    useEffect(() => {
        if (docDescError) {
            setDocDescError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.description]);

    useEffect(() => {
        if (docEffectiveFromError) {
            setdocEffectiveFromError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.effectiveFrom]);

    useEffect(() => {
        if (docSunsetError) {
            setDocSunsetError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.sunsetPeriod]);

    useEffect(() => {
        if (docEffectiveError) {
            setDocEffectiveError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.effectivePeriod]);

    useEffect(() => {
        if (isDocFetched) fetchAttachments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDocFetched]);

    useEffect(() => {
        const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
        setDocumentId(Number(queryObj.doc_id ? queryObj.doc_id : 0));
        setSelectedDocVersion({ label: '', value: 0 });
        setActiveStep('STEP1');
    }, [search]);

    const findGroupMember = async () => {
        // if (taskId) {
        const list: number[] = [];
        const approverList: any = [];
        if (approvers.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const approver of approvers) {
                approverList.push(...approver.approvers);
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const approverData of approverList) {
            if (Object.prototype.hasOwnProperty.call(approverData, 'groupId')) {
                list.push(approverData.groupId);
            }
        }
        if (list.length > 0) {
            const res = await getUserGroups();
            if (res?.apiStatus === 'SUCCESS') {
                const data = res.myGroups;
                const GroupList = data.map((d) => d.id);
                const isFounded = list.some((ai) => GroupList.includes(ai));
                setIsGroupMember(isFounded);
            }
        }
        // }
    };

    useEffect(() => {
        fetchApprovers();
        findGroupMember();
    }, [hideUsers]);

    useEffect(() => {
        const fetchTaskDetails = async () => {
            if (docInfo.versionOwner.id !== user.id && docInfo.itemCreator.id !== user.id) {
                if (taskId) {
                    const res = await getTaskDetails(taskId, user.id);
                    if (res) {
                        setDelegatorInfo(res.delegatorInfo);
                    }
                }
            }
        };

        fetchTaskDetails();
    }, [taskId]);


    return (
        <newDocumentContext.Provider
            value={{
                documentId,
                docInfo,
                fields,
                fieldsData,
                reviewers,
                approvers,
                externalApprovers,
                activeStep,
                submitDocStatus,
                userType,
                docVersions,
                docVersionsArr,
                selectedDocVersion,
                activeDocVersionObj,
                attachmentConfig,
                attachments,
                error,
                docTitleError,
                docDescError,
                docEffectiveFromError,
                docEffectiveError,
                docSunsetError,
                hideUsers,
                collectComment,
                collectCommentRejection,
                docTypeInfo,
                activeDocVersionId,
                canViewCreateDocument,
                canViewDocumentDetails,
                documentDispatch,
                reviewersDispatch,
                approversDispatch,
                externalApproversDispatch,
                saveFieldsData,
                updateFieldsData,
                isFieldsDataSaved,
                setActiveStep,
                onDocumentSubmit,
                onDocumentCheckIn,
                setDocInfo,
                setSelectedDocVersion,
                setActiveDocVersionObj,
                fetchDocumentVersion,
                validateReferenceDocuments,
                fetchDocument,
                fetchAttachments,
                setIsFileUploading,
                setHideUsers,
                accessibleByCurrentUser,
                isMemo,
                delegatorInfo,
                taskId,
                publishOnceApproved,
                isSunsetEnabled,
                isPublishLimitReached,
                isPRB,
                activeVersionOwnerInfo,
                checkInVersionOwner,
                allowViewInProcessDocFlag,
                versionNumberInfo,
                isLeadershipForm,
                isDCOwithApprovers,
                documentTypeId,
                submittedOn,
                dateErrorInfo,
                checklistErrorInfo,
                isEndDateAsked,
                isICMP,
                hideFunctionalAreaICMP,
                setHideCurrentGradeICMP,
                setHideFunctionalAreaICMP,
                hideCurrentGradeICMP,
                isTravelAuthorizationForm,
                isItACustomaerMeeting,
                setIsItACustomaerMeeting,
                travelRequestFor,
                setTravelRequestFor,
                changeApprovlStatusForTravelForm,
                docVersionCheckedInFlag,
                setDocVersionCheckedInFlag,
                versionState,
                activeVersionApprovalDeadline,
                setActiveVersionApprovalDeadline,
                checklist,
                setchecklist,
                relationArr,
                setRelationArr,
                latestrelationArr,
                setlatestrelationArr,
                existingContract,
                setExistingContract,
                disableApprovalFlow,
                setDisableApprovalFlow,
                activeApprovalStatusId,
                showVersionModel,
                setShowVersionModel,
                isGroupMember,
                changeApprovalStatusForLPNF,
                setIsFieldsDataSaved
            }}>
            {children}
        </newDocumentContext.Provider>
    );
};

export const useDocumentContext = (): IDocumentContext => useContext(newDocumentContext);

export default DocumentProvider;
